/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { Button, DatePicker, Input } from 'antd';
import { useState } from 'react';
import moment from 'moment';

import './style.scss';
import callApi from '../../Api/config';
import { DOCUMENT_USER_ADDITIONAL_DETAILS } from '../../constants/EntityName';
import { toastError, toastSuccess } from '../../service/Toast';

const DocumitraAdditionalDetail = (props: any) => {
    const [additionalDetail, setAdditionalDetail] = useState<any>({
        ...props,
    });

    const handleOnchange = (e: any) => {
        const { name, value } = e.target;

        setAdditionalDetail((p: any) => ({ ...p, [name]: value }));
    };

    const handleOnchangeDate = (name: any, dateOfBirth: any) => {
        const formattedDate = dateOfBirth ? moment(dateOfBirth).startOf('day').format('YYYY-MM-DD') : null;
        setAdditionalDetail((prevState: any) => ({ ...prevState, [name]: formattedDate }));
    };

    const disabledDate = (current: moment.Moment | null) => (current ? current > moment().endOf('day') : false);

    const handleChecked = (checkedName: string, isChecked: boolean) => {
        setAdditionalDetail((prev: any) => ({
            ...prev,
            [checkedName]: isChecked ? props[checkedName] || '' : '',
        }));
    };

    const submitAdditionalDetail = async () => {
        const { memberId, ...payload } = additionalDetail;
        try {
            const res = await callApi(`${DOCUMENT_USER_ADDITIONAL_DETAILS}/${props?.memberId}`, 'PATCH', payload);
            if (res?.data?.statusCode === 200) {
                toastSuccess(`User's additional details updated successfully.`);
            }
        } catch (error) {
            console.error('Error:', error);
            toastError(`An error occurred while updating member's additional details.`);
        }
    };

    return (
        <div className='additional-container'>
            <div className='additional-detail'>
                <label className='additional-label'>Reference Number:</label>
                <Input
                    type='text'
                    name='referenceNumber'
                    value={additionalDetail?.referenceNumber}
                    onChange={handleOnchange}
                />
            </div>
            <div className='additional-detail'>
                <label className='additional-label'>Date of Birth:</label>
                <DatePicker
                    name='dateOfBirth'
                    defaultPickerValue={moment(new Date())}
                    value={
                        // eslint-disable-next-line no-nested-ternary
                        additionalDetail.dateOfBirth
                            ? additionalDetail.dateOfBirth === ' '
                                ? moment(new Date())
                                : moment(additionalDetail.dateOfBirth)
                            : moment(new Date())
                    }
                    onChange={(dateOfBirth) => handleOnchangeDate('dateOfBirth', dateOfBirth)}
                    disabledDate={disabledDate}
                />
            </div>
            <div className='additional-detail'>
                <label className='additional-label'>Passport Number:</label>
                <Input
                    type='text'
                    name='passportNumber'
                    value={additionalDetail?.passportNumber}
                    onChange={handleOnchange}
                />
            </div>
            <div className='additional-detail'>
                <label className='additional-label'>Tracking Link:</label>
                <Input
                    type='text'
                    name='trackingLink'
                    value={additionalDetail?.trackingLink}
                    onChange={handleOnchange}
                />
            </div>
            <Button
                onClick={submitAdditionalDetail}
                style={{
                    width: '100px',
                    margin: '10px',
                    padding: '0px 9px',
                    borderRadius: '4px',
                    backgroundColor: '#586f80',
                    height: '28px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: 'white',
                }}>
                Update
            </Button>
        </div>
    );
};

export default DocumitraAdditionalDetail;
