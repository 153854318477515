import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
import { FORGOT_PASSWORD } from '../../constants/EntityName';

import './style.scss';

type FieldType = {
    username?: string;
    password?: string;
};

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const history = useHistory();

    const submitForm = async () => {
        try {
            const response: any = await callApi(FORGOT_PASSWORD, 'POST', { email });

            if (response?.status) {
                history.push({
                    pathname: '/login',
                });
                toastSuccess('A password reset link has been sent to your email address.');
            } else {
                toastError('Error Occurred.');
            }
        } catch (error) {
            toastError('Please Enter Email Id.');
        }
    };

    return (
        <div className='forgotPasswordContainer'>
            <Form name='basic' initialValues={{ remember: true }} autoComplete='on' layout='vertical'>
                <h1>Forgot Password</h1>
                <Form.Item<FieldType>
                    label='Email'
                    name='email'
                    rules={[
                        {
                            type: 'email',
                            message: 'The enter valid E-mail Id.',
                        },
                        {
                            required: true,
                            message: 'Please enter your E-mail Id.',
                        },
                    ]}>
                    <Input placeholder='Enter your email' onChange={(e: any) => setEmail(e.target.value)} />
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit' onClick={submitForm}>
                        Reset Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ForgotPassword;
