import { errorMsg } from '../constants/messages';

const ManagerTechnicianFormData = [
    {
        name: 'name',
        label: 'Manager Name',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Manager Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Manager E-mail' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'mobile',
        label: 'Mobile No',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Manager Mobile No' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'manager',
        label: 'Manager',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Manager',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Manager',
            enableSort: false,
            allowAddRecordClick: true,
            columnType: 'displayNameObject',
        },
        isTableHeader: false,
        isAccordian: true,
        default: [],
    },
    {
        name: 'technicians',
        label: 'Technicians',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an Technicians',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Technicians',
            columnType: 'displayNameObjectArray',
        },
        isTableHeader: true,
        isAccordian: true,
        default: [],
    },
];

export { ManagerTechnicianFormData };
