// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.additional-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin: 25px 0; }

.additional-detail {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center; }

.additional-detail .additional-label {
  margin: 0 10px;
  font-weight: 500; }

.additional-detail .ant-input:nth-child(1) {
  width: 20px;
  height: 20px; }

.additional-detail .ant-input:nth-last-child(1) {
  width: 300px;
  height: 30px; }
`, "",{"version":3,"sources":["webpack://./src/components/DocumitraAdditionalDetail/style.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,cAAc,EAAA;;AAGlB;EACI,WAAW;EACX,aAAa;EACb,SAAS;EACT,mBAAmB,EAAA;;AAGvB;EACI,cAAc;EACd,gBAAgB,EAAA;;AAGpB;EACI,WAAW;EACX,YAAY,EAAA;;AAGhB;EACI,YAAY;EACZ,YAAY,EAAA","sourcesContent":[".additional-container{\n    width: 100%;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    gap: 10px;\n    margin: 25px 0;\n}\n\n.additional-detail{\n    width: 100%;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n}\n\n.additional-detail .additional-label{\n    margin: 0 10px;\n    font-weight: 500;\n}\n\n.additional-detail .ant-input:nth-child(1){\n    width: 20px;\n    height: 20px;\n}\n\n.additional-detail .ant-input:nth-last-child(1){\n    width: 300px;\n    height: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
