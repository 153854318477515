import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const AboutPageOurTeamFormData = [
    {
        title: 'Our Team Details',
        name: 'id',
        label: 'Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Id', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'url',
        label: 'Image url',
        fieldType: {
            name: 'aboutPageOurTeamFile',
            accept: acceptableImageFormat,
            maxCount: 1,
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Image url', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { AboutPageOurTeamFormData };
