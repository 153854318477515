import { errorMsg } from '../constants/messages';

const faqsFormData = [
    {
        title: 'Faq Details',
        name: 'question',
        label: 'Question',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Question', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'answer',
        label: 'Answer',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Answer' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { faqsFormData };
