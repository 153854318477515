import { errorMsg } from '../constants/messages';

const ComplaintStatusFormData = [
    {
        title: 'Complaint Status Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'priority',
        label: 'Priority',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: 'Priority should be in number',
        tableMeta: { title: 'Priority' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ComplaintStatusFormData };
