import { useState } from 'react';
import { Card, Form } from 'antd';

import UserModal from './UserModal';

interface propTypes {
    formInput: any;
    index: number;
    isCreateMode?: boolean;
    formDetails?: any;
}

const RefferalUserCard = (props: propTypes) => {
    const { formDetails, formInput, index, isCreateMode } = props;
    const [openUserModal, setOpenUserModal] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const users: any = (formDetails?.results && formDetails?.results[formInput?.name]) || [];
    const hasUsers = users.length > 0;

    return (
        <>
            <Form.Item
                name={formInput?.name}
                label={formInput?.label}
                wrapperCol={{ span: 8, offset: 3 }}
                labelCol={{ span: 5 }}
                labelAlign='left'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <Card
                    key={index}
                    style={{
                        marginBottom: '25px',
                        width: '566px',
                        borderRadius: '8px',
                        border: 'solid 1px #d9d9d9',
                    }}>
                    {hasUsers ? (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {users?.map((user: any) => (
                                    <div
                                        style={{
                                            padding: '4px',
                                            cursor: 'pointer',
                                            backgroundColor: '#80808029',
                                            marginBottom: '2px',
                                            marginLeft: '4px',
                                            inlineSize: 'fit-content',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                        key={user?.id}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: '7.5px',
                                            }}
                                            onClick={() => {
                                                setCurrentUser(user);
                                                setOpenUserModal(!openUserModal);
                                            }}>
                                            {user?.name}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div>No refferals</div>
                    )}
                </Card>
            </Form.Item>
            <UserModal
                currentUser={currentUser}
                setCurrentUser={setCurrentUser}
                openUserModal={openUserModal}
                setOpenUserModal={setOpenUserModal}
                isCreateMode={isCreateMode}
                heading={formInput?.name}
            />
        </>
    );
};

export default RefferalUserCard;
