import { errorMsg } from '../../constants/messages';

const PackageProcessData = [
    {
        title: 'Package Process Details:',
        name: 'id',
        label: 'Process ID',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: { title: 'Process ID', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'packages',
        label: 'Package',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Package',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Package',
            enableSort: false,
            allowAddRecordClick: true,
            columnType: 'packageName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'sequence',
        label: 'Sequence',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sequence' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'heading',
        label: 'Heading',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Heading' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textEditor',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'subtitle',
        label: 'Process Details',
        fieldType: {
            name: 'packageProcess',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Review Process', columnType: 'clickHere', allowAddRecordClick: true },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { PackageProcessData };
