import { errorMsg } from '../../constants/messages';

const FormPageData = [
    {
        title: 'Form Details',
        name: 'formName',
        label: 'Form Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Form Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'package',
        label: 'package',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an package',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'package',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'packageName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        title: 'Form Steps',
        name: 'steps',
        label: 'Define Steps',
        fieldType: {
            name: 'formWithQuestions',
            options: [],
            inputOption: 'option',
            minCount: 1,
            maxCount: 4,
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Add Form Steps',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'questionsDisplayNameObject',
        },
        isTableHeader: false,
        isAccordian: true,
        // default: [{ stepQuestions: [], sequence: '', id: '', stepTitle: '' }],
    },
];

export { FormPageData };
