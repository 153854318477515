import { Modal, Button } from 'antd';
import { useEffect, useState } from 'react';

import ImageDragDropContainer from './ImageDragDropContainer';
import callApi from '../Api/config';
import { PHOTO_ORDER_APPLICATION } from '../constants/EntityName';
import { toastError } from '../service/Toast';
import { failedMsg } from '../constants/messages';

const photoTypes = {
    PROCESSED_PHOTO: 'processedPhoto',
    PROCESSED_GRID_PHOTO: 'processedPhotoGrid',
};

const PhotoUploadModal = (props: any) => {
    const { openPhotoUploadModal, setOpenPhotoUploadModal, activeItem, itemNumber, formDetails } = props;

    const [processedPhoto, setProcessedPhoto] = useState<any>({});
    const [processedPhotoGrid, setProcessedPhotoGrid] = useState<any>({});

    const updateItemsProcessedPhoto = async (validPhotos: any) => {
        try {
            const response = await callApi(
                `${PHOTO_ORDER_APPLICATION}/${formDetails?.id}/items/${activeItem?.id}`,
                'PATCH',
                validPhotos,
            );
            if (response?.status) {
                const newItem = response?.data?.data;
                if (newItem) {
                    for (let i = 0; i < formDetails?.items.length; i++) {
                        if (formDetails?.items[i]?.id === newItem?.id) {
                            formDetails.items[i] = newItem;
                        }
                    }
                }
                setOpenPhotoUploadModal(!openPhotoUploadModal);
            } else {
                toastError(`Oops, an error occured! creation failed. ${response?.data?.message || failedMsg}`);
            }
        } catch (error) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const submitForm = () => {
        let validPhotos = {};
        if (processedPhoto && Object.keys(processedPhoto).length > 0) {
            validPhotos = { ...validPhotos, processedPhoto };
        }
        if (processedPhotoGrid && Object.keys(processedPhotoGrid).length > 0) {
            validPhotos = { ...validPhotos, processedPhotoGrid };
        }

        updateItemsProcessedPhoto(validPhotos);
    };

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        const items = formDetails?.items || [];
        const updatedItem = items?.filter((item: any) => {
            if (item?.id === activeItem?.id) {
                return item;
            }
        });
        setProcessedPhoto(updatedItem[0]?.processedPhoto);
        setProcessedPhotoGrid(updatedItem[0]?.processedPhotoGrid);
    }, [formDetails, activeItem]);

    return (
        <Modal
            key={activeItem?.id}
            visible={openPhotoUploadModal}
            onOk={() => setOpenPhotoUploadModal(!openPhotoUploadModal)}
            onCancel={() => setOpenPhotoUploadModal(!openPhotoUploadModal)}
            title={`User: ${itemNumber + 1} - Processed Photos`}
            style={{ width: '600px' }}
            footer={null}>
            <div key={activeItem?.id} style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <ImageDragDropContainer
                    fallbackImage
                    activeItem={activeItem}
                    photoTypes={photoTypes.PROCESSED_PHOTO}
                    photoType={'Processed Photo'}
                    photoDetails={processedPhoto}
                    setPhotoDetails={setProcessedPhoto}
                />
                <ImageDragDropContainer
                    fallbackImage
                    activeItem={activeItem}
                    photoTypes={photoTypes.PROCESSED_GRID_PHOTO}
                    photoType={'Processed Grid Photo'}
                    photoDetails={processedPhotoGrid}
                    setPhotoDetails={setProcessedPhotoGrid}
                />
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px', marginTop: '20px' }}>
                <Button key='cancel' onClick={() => setOpenPhotoUploadModal(!openPhotoUploadModal)}>
                    Cancel
                </Button>
                <Button
                    style={{ backgroundColor: '#586f80', color: 'white', borderRadius: '4px', border: 'none' }}
                    type='primary'
                    onClick={() => submitForm()}>
                    Update
                </Button>
            </div>
        </Modal>
    );
};

export default PhotoUploadModal;
