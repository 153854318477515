import { errorMsg } from '../constants/messages';

const contactFormFormData = [
    {
        name: 'id',
        label: 'ID',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        title: 'Contact Form Details',
        name: 'firstName',
        label: 'First Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'First Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Last Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'countryCode',
        label: 'Country Code',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Country Code' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'contactNumber',
        label: 'Contact Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact Number' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'reasonForContact',
        label: 'Reason For Contact',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Reason For Contact' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'clientType',
        label: 'Client Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Client Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'createdAt',
        label: 'Date Of Submission',
        fieldType: {
            name: 'text',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Date Of Submission' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'message',
        label: 'message',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'message' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { contactFormFormData };
