import { Card, Form, FormInstance, Input, Select } from 'antd';
import { useEffect, useState } from 'react';

import Text from 'antd/lib/typography/Text';

import { InputInterface } from '../utils/forms/FormInterface';

const { Option } = Select;

interface timelineProps {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}

const TimelineTable = (props: timelineProps) => {
    const { formDetails, form } = props;
    const [table, setTable] = useState<any>({
        rows: 2,
        cols: 2,
    });
    const [tableFormat, setTableFormat] = useState<any>([
        ['', ''],
        ['', ''],
    ]);
    const rowOptions = [
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
        { name: '7', value: 7 },
        { name: '8', value: 8 },
        { name: '9', value: 9 },
    ];
    const colOptions = [
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
        { name: '5', value: 5 },
        { name: '6', value: 6 },
    ];

    const handleTableChange = (name: string) => (value: any) => {
        setTable((prevTable: any) => ({ ...prevTable, [name]: value }));
    };

    const handleTableInputs = (path: string) => (event: any) => {
        const [row, col] = path.split('-');
        const newtable = tableFormat;
        newtable[row][col] = event?.target?.value || '';
        setTableFormat(() => [...newtable]);
        const stringyfiedTable = newtable;
        form.setFieldValue('table', stringyfiedTable);
    };

    const getTableFormat = () => (
        <Card
            style={{
                marginBottom: '25px',
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}>
            {tableFormat?.map((row: any, rIdx: number) => (
                <div key={`${rIdx}`} style={{ display: 'flex', flexDirection: 'row' }}>
                    {row?.map((col: any, cIdx: number) => (
                        <Input
                            key={`${rIdx}-${cIdx}`}
                            maxLength={100}
                            value={tableFormat[rIdx][cIdx]}
                            placeholder={`${rIdx === 0 ? 'Header' : 'Value'}`}
                            onChange={handleTableInputs(`${rIdx}-${cIdx}`)}
                            style={{
                                width: '100%',
                            }}
                        />
                    ))}
                </div>
            ))}
        </Card>
    );

    useEffect(() => {
        const newformat = [];
        for (let i = 0; i < table?.rows; i++) {
            const newColumns = [];
            for (let j = 0; j < table?.cols; j++) {
                newColumns.push('');
            }
            newformat.push(newColumns);
        }

        const tableRows = tableFormat?.length <= table?.rows ? tableFormat?.length : table?.rows;
        const tableCols = tableFormat[0]?.length <= table?.cols ? tableFormat[0]?.length : table?.cols;

        for (let i = 0; i < tableRows; i++) {
            for (let j = 0; j < tableCols; j++) {
                newformat[i][j] = tableFormat[i][j];
            }
        }
        setTableFormat(newformat);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table]);

    useEffect(() => {
        if (!formDetails) {
            return;
        }

        const table = JSON.parse(formDetails?.table);
        setTableFormat(() => {
            setTable(() => ({
                rows: table.length,
                cols: table[0].length,
            }));
            return table;
        });
    }, [formDetails]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '16px' }}>Enter no of rows:</Text>
                <Select
                    id='activeRows'
                    placeholder='Select horizontal rows'
                    disabled={false}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    onChange={handleTableChange('rows')}
                    value={table?.rows}
                    style={{ width: '50%' }}>
                    {rowOptions?.map((obj: any) => (
                        <Option key={obj?.value} value={obj?.value} name={obj?.name} selected>
                            {obj?.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text style={{ fontSize: '16px' }}>Enter no of columns:</Text>{' '}
                <Select
                    id='activeCols'
                    placeholder='Select vertical columns'
                    disabled={false}
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    onChange={handleTableChange('cols')}
                    value={table?.cols}
                    style={{ width: '50%' }}>
                    {colOptions?.map((obj: any) => (
                        <Option key={obj?.value} value={obj?.value} name={obj?.name} selected>
                            {obj?.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <Form.Item
                name={'table'}
                style={{ width: '100%' }}
                rules={[
                    {
                        required: false,
                        message: 'Please select question',
                    },
                ]}>
                <Text style={{ fontSize: '16px' }}>Fill Table:</Text>
                {getTableFormat()}
            </Form.Item>
        </div>
    );
};

export default TimelineTable;
