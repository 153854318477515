import { errorMsg } from '../../constants/messages';

const photoSizeFormData = [
    {
        title: 'Photo Size Details',
        name: 'label',
        label: 'Label',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Label', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'value',
        label: 'Dimensions',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Dimensions', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { photoSizeFormData };
