import { Button, Form, Input, Modal, Image } from 'antd';

import { useEffect, useState } from 'react';

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import callApi, { uploadImageApiCall } from '../Api/config';
import { failedMsg } from '../constants/messages';
import { fallback } from '../constants/FallbackImage';

import { toastSuccess, toastError } from '../service/Toast';
import { APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES } from '../constants/EntityName';
import { BASE_IMAGE_URL } from '../env';

const AttributeModal = (props: any) => {
    const {
        openAttributeModal,
        setOpenAttributeModal,
        setAtrributes,
        parentForm,
        childFrom,
        currentAttribute,
        setCurrentAttribute,
        attributes,
    } = props;
    const [imageUrl, setImageUrl] = useState<any>('');

    const newIndex = currentAttribute !== null ? currentAttribute : attributes?.length;

    const createEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(entityName, 'POST', payload);
            if (res?.status) {
                const { data } = res;
                toastSuccess(`created successfully!`);
                setAtrributes((prevState: any) => {
                    parentForm.setFieldValue('serviceAttributes', [...prevState, data?.data]);
                    return [...prevState, data?.data];
                });
                setImageUrl('');
                setOpenAttributeModal(!openAttributeModal);
                childFrom.resetFields();
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };
    const updateEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(`${entityName}/${attributes[currentAttribute]?.id}`, 'PATCH', payload);
            if (res?.status) {
                const { data } = res;
                toastSuccess(`updated successfully!`);
                const updatedAttributes = attributes;
                updatedAttributes[currentAttribute] = data?.data;

                setAtrributes(() => {
                    parentForm.setFieldValue('serviceAttributes', [...updatedAttributes]);
                    return [...updatedAttributes];
                });
                setCurrentAttribute(null);
                setOpenAttributeModal(!openAttributeModal);
            } else {
                toastError(`Oops, an error occured! updation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! updation failed. Please contact development support team`);
        }
    };

    const handleChange = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('iconImage', file);

        try {
            const res: any = await uploadImageApiCall(`/documitra-content/upload`, formData);
            const { data, isSuccess } = res;
            if (isSuccess) {
                setImageUrl(data);
                childFrom.setFieldValue('iconImage', data);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const submitForm = (values: any) => {
        const payload = {
            name: values?.[`${newIndex}-name`],
            value: values?.[`${newIndex}-value`],
            iconImage: imageUrl,
        };

        if (currentAttribute !== null) {
            updateEntity(payload, APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES);
        } else {
            createEntity(payload, APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES);
        }
        childFrom.resetFields();
    };

    const onDownload = async (fileUrl: any) => {
        if (fileUrl) {
            window.open(fileUrl);
        }
    };

    useEffect(() => {
        if (currentAttribute !== null) {
            childFrom.setFieldValue(`${currentAttribute}-name`, attributes[currentAttribute]?.name);
            childFrom.setFieldValue(`${currentAttribute}-value`, attributes[currentAttribute]?.value);
            childFrom.setFieldValue(`${currentAttribute}-iconImage`, attributes[currentAttribute]?.iconImage);
            setImageUrl(attributes[currentAttribute]?.iconImage);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentAttribute]);

    return (
        <Modal
            title={`Add Service Attribute:`}
            visible={openAttributeModal}
            onOk={() => {
                childFrom.setFieldValue(`${currentAttribute}-name`, '');
                childFrom.setFieldValue(`${currentAttribute}-value`, '');
                childFrom.setFieldValue(`${currentAttribute}-iconImage`, '');
                setImageUrl('');
                setCurrentAttribute(null);
                setOpenAttributeModal(!openAttributeModal);
            }}
            onCancel={() => {
                childFrom.setFieldValue(`${currentAttribute}-name`, '');
                childFrom.setFieldValue(`${currentAttribute}-value`, '');
                childFrom.setFieldValue(`${currentAttribute}-iconImage`, '');
                setImageUrl('');
                setCurrentAttribute(null);
                setOpenAttributeModal(!openAttributeModal);
            }}
            style={{ width: '600px' }}
            footer={null}>
            <Form form={childFrom} onFinish={submitForm}>
                <Form.Item
                    name={`${newIndex}-name`}
                    label='Name'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute name',
                        },
                    ]}>
                    <Input key={`${newIndex}-name`} name='name' placeholder='Enter' style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name={`${newIndex}-value`}
                    label='Value'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute value',
                        },
                    ]}>
                    <Input key={`${newIndex}-value`} name='value' placeholder='Enter' style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                    name={`${newIndex}-iconImage`}
                    label='Attribute Icon (*.png, *jpg)'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute icon',
                        },
                    ]}>
                    {imageUrl ? (
                        <div style={{ position: 'relative', width: '150px' }}>
                            <Image
                                width={150}
                                src={
                                    imageUrl?.startsWith('https') || !imageUrl
                                        ? imageUrl
                                        : `${BASE_IMAGE_URL}/${imageUrl}`
                                }
                                fallback={fallback}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '4px',
                                    right: '4px',
                                    display: 'flex',
                                    gap: '10px',
                                }}>
                                <DeleteOutlined
                                    style={{
                                        backgroundColor: '#00000070',
                                        padding: '4px',
                                        borderRadius: '4px',
                                        fontSize: 20,
                                        color: 'white',
                                    }}
                                    onClick={() => setImageUrl('')}
                                />
                                <DownloadOutlined
                                    style={{
                                        backgroundColor: '#00000070',
                                        borderRadius: '4px',
                                        padding: '4px',
                                        fontSize: 20,
                                        color: 'white',
                                    }}
                                    onClick={() => onDownload(imageUrl)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <input
                                onChange={(e) => handleChange(e)}
                                type='file'
                                id='attributeImage'
                                name='attributeImage'
                                accept='image/png, image/jpeg'
                            />
                        </div>
                    )}
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button key='cancel' onClick={() => setOpenAttributeModal(!openAttributeModal)}>
                            Cancel
                        </Button>
                        <Button
                            style={{
                                backgroundColor: '#586f80',
                                color: 'white',
                                borderRadius: '4px',
                                border: 'none',
                            }}
                            type='primary'
                            htmlType='submit'>
                            {currentAttribute !== null ? 'Update Attribute' : 'Add Attribute'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AttributeModal;
