import { errorMsg } from '../constants/messages';

const RefferalDetailsFormData = [
    {
        title: 'User Details',
        name: 'name',
        label: 'User Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'email',
        label: 'Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Email' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'totalRefferals',
        label: 'Total Refferals',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Total Refferals' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'refferalCompleted',
        label: 'Completed Refferals',
        fieldType: {
            name: 'refferalUserCard',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Completed Refferals' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'refferalIncompleted',
        label: 'Incompleted Refferals',
        fieldType: {
            name: 'refferalUserCard',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Incompleted Refferals' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { RefferalDetailsFormData };
