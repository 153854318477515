import { inputTypesOptions } from '../../constants/AppConstant';
import { errorMsg } from '../../constants/messages';
import { NO_NUMERICS } from '../../utils/Regexp/Regex';

const FormQuestionFormData = [
    {
        title: 'Question Details',
        name: 'id',
        label: 'Question ID',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Question ID', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'label',
        label: 'Question Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        rules: [{ pattern: NO_NUMERICS, message: 'Numeric values are not allowed.' }],
        tableMeta: { title: 'Question Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'inputType',
        label: 'Input Type',
        placeholder: 'Click here to select an Input Type',
        fieldType: {
            name: 'select',
            options: inputTypesOptions,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Input Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'options',
        label: 'Add Options',
        fieldType: {
            name: 'checkboxType',
            minCount: 1,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Add Options',
        },
        isTableHeader: false,
        isAccordian: true,
    },
    {
        name: 'validations',
        label: 'Add Validation',
        fieldType: {
            name: 'MultipleTwinInputforValidation',
            options: [],
            inputOption: 'option',
            minCount: 1,
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Add Form Steps',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'questionsDisplayNameObject',
        },
        isTableHeader: false,
        isAccordian: true,
        // default: [{ type: [], value: '', errorMessage: '' }],
    },
    {
        name: 'placeholder',
        label: 'Placeholder',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Placeholder' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'desktopWidth',
        label: 'Desktop Width',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Desktop Width' },
        isTableHeader: true,
        isAccordian: true,
        default: 100,
    },
    {
        name: 'mobileWidth',
        label: 'Mobile Width',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Mobile Width' },
        isTableHeader: true,
        isAccordian: true,
        default: 50,
    },
    {
        name: 'tabletWidth',
        label: 'Tablet Width',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Tablet Width' },
        isTableHeader: true,
        isAccordian: true,
        default: 75,
    },
    {
        name: 'note',
        label: 'Note',
        fieldType: {
            name: 'informationNote',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Note' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { FormQuestionFormData };
