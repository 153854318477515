import { errorMsg } from '../constants/messages';

const subCategoriesPageFormData = [
    {
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'category',
        label: 'Category',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an category',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Category',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'displayName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];

export { subCategoriesPageFormData };
