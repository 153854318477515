import { documitraOrderStatusOption } from '../constants/AppConstant';
import { errorMsg } from '../constants/messages';

const DocumitraOrderApplication = [
    {
        title: 'Documitra Order Details',
        name: 'orderId',
        label: 'ID',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: { title: 'Order ID', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'review',
        label: 'Review order',
        fieldType: {
            name: 'reviewDocumitraOrder',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Review Order', columnType: 'clickHere', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'userName',
        label: 'User Name',
        fieldType: {
            name: 'text',
        },
        required: false,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Name', allowAddRecordClick: false, columnType: 'orderUserName' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'userEmail',
        label: 'User Email',
        fieldType: {
            name: 'text',
        },
        required: false,
        readonly: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Email', allowAddRecordClick: false, columnType: 'orderUserEmail' },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'serviceName',
        label: 'Service Name',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Service Name', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'packageName',
        label: 'Package Name',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Package Name', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'status',
        label: 'Status',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Status', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'paymentStatus',
        label: 'Payment Status',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Payment Status', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'createdAt',
        label: 'Date of Submission',
        fieldType: {
            name: 'select',
            options: documitraOrderStatusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Date of Submission', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'trackingId',
        label: 'Tracking Id',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Tracking Id', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { DocumitraOrderApplication };
