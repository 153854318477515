import { InputInterface } from './FormInterface';

const getFormTemplateData = (formPayload: Array<InputInterface>): Array<InputInterface> => formPayload;

const getTableHeaders = (formPayload: Array<InputInterface>) => {
    const tableField = formPayload.filter((d) => d.isTableHeader);

    const tableHeaders = tableField.map((x) => ({
        tableKey: x.name,
        tableLabel: x.tableMeta.title,
        enableSort: x.tableMeta.enableSort,
        type: x.tableMeta.type || 'default',
        ...x.tableMeta,
    }));

    return [...tableHeaders];
};

const getFormDefaultValue = (formPayload: Array<InputInterface>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const defaultValue: any = {};
    formPayload?.forEach((value: InputInterface) => {
        defaultValue[value.name] = value.default;

        // This condition is for Items tab.
        if (value.name === 'tags') {
            defaultValue[value.name] = value.default.split(',');
        }
        if (value.name === 'contactNumbers') {
            defaultValue[value.name] = value.default.split(',');
        }
    });

    return defaultValue;
};

export { getFormTemplateData, getTableHeaders, getFormDefaultValue };
