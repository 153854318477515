/* eslint-disable no-unused-vars */
import { Tabs } from 'antd';

import { TabContent } from '../../../utils/forms/FormInterface';

const { TabPane } = Tabs;

export type TabActiosProps = 'add' | 'remove';
export type TabActiosEventType = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>;

interface AbstractTabsProps {
    activeKey: string;
    entityName: string;
    children: React.ReactNode;
    updateFormVisibility: (params: TabActiosProps, _targetKey: string | TabActiosEventType) => void;
    onTabClick: (key: string) => void;
    secondTabTitle?: string;
    showFormComponent?: boolean;
    content?: TabContent;
    formComponent?: React.ReactNode;
    storeInventoryLanding?: React.ReactNode;
    processedPhotoLanding?: React.ReactNode;
    variantPriceLanding?: React.ReactNode;
    storeAddOnsLanding?: React.ReactNode;
    deliverySlotLanding?: React.ReactNode;
    storeBulkAddOnsLanding?: React.ReactNode;
    storeDashboardLanding?: React.ReactNode;
    multipleTabTitle?: string[];
}

const AbstractTabs = (props: AbstractTabsProps) => {
    const {
        storeInventoryLanding,
        processedPhotoLanding,
        variantPriceLanding,
        deliverySlotLanding,
        storeAddOnsLanding,
        storeBulkAddOnsLanding,
        storeDashboardLanding,
    } = props;
    const onTabClick = (key: string) => {
        props.onTabClick(key);
    };

    const onEdit = (_targetKey: string | TabActiosEventType, action: TabActiosProps) => {
        props.updateFormVisibility(action, _targetKey);
    };

    return (
        <section className='abstractTabs'>
            <Tabs
                hideAdd
                type='editable-card'
                activeKey={props.activeKey}
                onTabClick={onTabClick}
                onEdit={onEdit}
                size={'large'}>
                <TabPane tab={props.entityName} key='0' closable={false}>
                    {props.children}
                </TabPane>
                {props.showFormComponent && (
                    <TabPane tab={props.secondTabTitle} key={'a'} closable={true}>
                        {props.formComponent}
                    </TabPane>
                )}

                {storeInventoryLanding && (
                    <TabPane tab='Store Inventory' key='store-inventory' closable={true}>
                        {storeInventoryLanding}
                    </TabPane>
                )}

                {variantPriceLanding && (
                    <TabPane tab='Store Variant' key='store-variant' closable={true}>
                        {variantPriceLanding}
                    </TabPane>
                )}

                {storeAddOnsLanding && (
                    <TabPane tab='Store Add-ons' key='store-addons' closable={true}>
                        {storeAddOnsLanding}
                    </TabPane>
                )}

                {storeBulkAddOnsLanding && (
                    <TabPane tab='store-bulk-addons' key='store-bulk-addons' closable={true}>
                        {storeBulkAddOnsLanding}
                    </TabPane>
                )}

                {storeDashboardLanding && (
                    <TabPane tab='store-dashboard' key='store-dashboard' closable={true}>
                        {storeDashboardLanding}
                    </TabPane>
                )}

                {deliverySlotLanding && (
                    <TabPane tab='Delivery Slot' key='delivery-slot' closable={true}>
                        {deliverySlotLanding}
                    </TabPane>
                )}

                {props?.content?.length &&
                    props.content.map((item: any) => (
                        <TabPane tab={item.tabTitle} key={item.key} closable={true}>
                            {item.content}
                        </TabPane>
                    ))}
            </Tabs>
        </section>
    );
};

export default AbstractTabs;
