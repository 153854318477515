/* eslint-disable @typescript-eslint/no-explicit-any */
export const UPDATE_PROJECT_DETAILS = 'UPDATE_PROJECT_DETAILS';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const UPDATE_STORE = 'UPDATE_STORE';
export const FETCH_PROJECT_DETAILS = 'FETCH_PROJECT_DETAILS';
export const FETCH_INVOICE_DETAILS = 'FETCH_INVOICE_DETAILS';

// Action Type .......................

interface IUpdateTokenAction {
    type: typeof UPDATE_TOKEN;
    token: string;
}
interface IUpdateStoreAction {
    type: typeof UPDATE_STORE;
    storeId: string;
}

const updateTokensAction = (token: string): GlobalActions => ({
    type: UPDATE_TOKEN,
    token,
});

const updateStoreAction = (storeId: string): GlobalActions => ({
    type: UPDATE_STORE,
    storeId,
});

interface IUpdateProjectdetailsAction {
    type: typeof UPDATE_PROJECT_DETAILS;
    payload: any;
}

interface IFetchProjectdetailsAction {
    type: typeof FETCH_PROJECT_DETAILS;
    id: number;
}
interface IFetchInvoicedetailsAction {
    type: typeof FETCH_INVOICE_DETAILS;
    id: number;
}

const updateProjectDetailsAction = (projectObj: any): GlobalActions => ({
    type: UPDATE_PROJECT_DETAILS,
    payload: projectObj,
});

const fetchProjectDetailsAction = (id: number): GlobalActions => ({
    type: FETCH_PROJECT_DETAILS,
    id,
});
const fetchInvoiceDetailsAction = (id: number): GlobalActions => ({
    type: FETCH_INVOICE_DETAILS,
    id,
});

// Root Action Type
type GlobalActions =
    | IUpdateProjectdetailsAction
    | IFetchProjectdetailsAction
    | IUpdateTokenAction
    | IUpdateStoreAction
    | IFetchInvoicedetailsAction;

export {
    updateProjectDetailsAction,
    fetchProjectDetailsAction,
    updateTokensAction,
    updateStoreAction,
    fetchInvoiceDetailsAction,
};

export type { GlobalActions, IFetchProjectdetailsAction };
