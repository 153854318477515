import { Button, Form, Input } from 'antd';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';

import callApi from '../../Api/config';
import { toastError, toastSuccess } from '../../service/Toast';
import { RESET_PASSWORD } from '../../constants/EntityName';
import { REGEX_PASSWORD } from '../../utils/Regexp/Regex';

import './style.scss';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
};

const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState<boolean>(false);

    const history = useHistory();
    const { search } = history.location;

    const submitForm = async () => {
        if (confirmPassword === password) {
            try {
                const response: any = await callApi(RESET_PASSWORD + search, 'PATCH', { password });
                if (response?.status) {
                    history.push({
                        pathname: '/login',
                    });
                    toastSuccess('Password updated successfully.');
                    setPasswordError(false);
                } else {
                    toastError('Error Occurred.');
                }
            } catch (error) {
                toastError('Enter Email');
            }
        } else {
            setPasswordError(true);
        }
    };

    return (
        <div className='resetPasswordContainer'>
            <Form name='basic' initialValues={{ remember: true }} autoComplete='off' layout='vertical'>
                <h1>Create Password</h1>
                <Form.Item<FieldType>
                    label='Set Password'
                    name='newPassword'
                    rules={[
                        { required: true, message: 'Please enter your password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Enter new password'
                        onChange={(e: any) => setPassword(e.target.value)}
                    />
                </Form.Item>
                <Form.Item<FieldType>
                    label='Re-Enter Password'
                    name='confirmPassword'
                    rules={[
                        { required: true, message: 'Please enter your password!' },
                        { pattern: REGEX_PASSWORD, message: 'Please enter a valid password!' },
                    ]}>
                    <Input.Password
                        placeholder='Re-Enter new password'
                        onChange={(e: any) => setConfirmPassword(e.target.value)}
                    />
                </Form.Item>

                <p
                    style={{
                        color: 'red',
                        visibility: passwordError ? 'visible' : 'hidden',
                    }}>
                    Please check both passwords.
                </p>

                <Form.Item>
                    <Button type='primary' htmlType='submit' onClick={submitForm}>
                        Save Password
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ResetPassword;
