import { acceptableImageFormat } from '../constants/FilterDataValues';
import { errorMsg } from '../constants/messages';

const complaintsPageFormData = [
    {
        name: 'subject',
        label: 'subject',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'subject', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'asset',
        label: 'Asset Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Asset Name', enableSort: false },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', enableSort: false },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'comment',
        label: 'Comment',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Comment', enableSort: false },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'defectType',
        label: 'Defect Type',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Defect Type',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Defect Type', enableSort: false, columnType: 'displayName' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'complaintImages',
        label: 'Complaint Image URL',
        fieldType: {
            name: 'file',
            accept: acceptableImageFormat,
            maxCount: 3,
            allowImageCropping: true,
        },
        placeholder: 'Click here to select an image',
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Complaint Image URL', enableSort: false, columnType: 'image' },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { complaintsPageFormData };
