import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

const { Option } = Select;

import './style.scss';
import callApi from '../Api/config';
import { COUNTRY } from '../constants/EntityName';

interface SelectInputProps {
    formInput: InputInterface;
    setFormData?: any;
    form: any;
    index: number;
    isCreateMode?: boolean;
}

const DynamicShowKeys = (props: SelectInputProps) => {
    const { formInput, form } = props;
    const {
        fieldType: { options },
    } = formInput;

    const [applyingForm, setApplyingForm] = useState<boolean>(false);
    const [destination, setDestination] = useState<boolean>(false);
    const [citizenship, setCitizenship] = useState<boolean>(false);
    const [countriesList, setCountriesList] = useState<any[]>([]);

    const changeHandler = (value: any) => {
        // Find the selected option based on the value
        const selectedOption = options.find((option: any) => option.value === value);

        const applyingFromKey = selectedOption?.parameters?.includes('applyingFrom');
        setApplyingForm(applyingFromKey);
        const destinationKey = selectedOption?.parameters?.includes('destination');
        setDestination(destinationKey);
        const citizenshipKey = selectedOption?.parameters?.includes('citizenship');
        setCitizenship(citizenshipKey);
    };

    const handleParameterCountries = (name: any) => (value: any) => {
        if (value.includes('all')) {
            const selectedCountries = countriesList.map((country: any) => country?.value);
            form.setFieldValue(name, [...selectedCountries]);
            return;
        }
    };

    // This useEffect is used for Country List API
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const entityData: any = await callApi(`${COUNTRY}`, 'GET');
                // Assuming entityData is an array of country names
                const countryNames: string[] = entityData.data.data;

                // Convert array of strings to array of objects with 'value' and 'name' with 'uniqueId' properties
                const countryData = countryNames.map((countryName, index) => ({
                    id: index + 1,
                    value: countryName,
                    name: countryName,
                }));

                if (countryData) {
                    setCountriesList(countryData);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching countries list:', error);
                setCountriesList([]); // Set an empty array in case of an error
            }
        };

        fetchCountries();
    }, []);

    return (
        <>
            <Form.Item
                label={'Service'}
                name={'service'}
                className='text'
                wrapperCol={{ span: 8, offset: 4 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='children'
                    onChange={changeHandler}
                    placeholder={formInput.placeholder}
                    disabled={formInput.readonly}
                    style={{ width: '100%' }}>
                    {formInput.fieldType.options?.map((obj: any, index: number) => (
                        <Option key={index} value={obj.value} additionaldata={obj.additionalData}>
                            {obj.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {applyingForm && (
                <Form.Item
                    label='Applying Form Country'
                    name='applyingFrom'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        placeholder='Click here to select country'
                        onChange={handleParameterCountries('applyingFrom')}
                        disabled={formInput.readonly}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {countriesList?.map((obj: any, index: number) => (
                            <Option key={index + 1} value={obj.value}>
                                {obj.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            {destination && (
                <Form.Item
                    label='Destination Country'
                    name='destination'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        placeholder='Click here to select country'
                        onChange={handleParameterCountries('destination')}
                        disabled={formInput.readonly}
                        style={{ width: '100%' }}>
                        {countriesList?.map((obj: any, index: number) => (
                            <Option key={index} value={obj.value}>
                                {obj.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {citizenship && (
                <Form.Item
                    label='Citizenship Country'
                    name='citizenship'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        placeholder='Click here to select country'
                        onChange={handleParameterCountries('citizenship')}
                        disabled={formInput.readonly}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {countriesList?.map((obj: any, index: number) => (
                            <Option key={index + 1} value={obj.value}>
                                {obj.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
        </>
    );
};

export default DynamicShowKeys;
