/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { Button, MenuProps, Modal } from 'antd';
import { useEffect, useState } from 'react';

import Search from '../../../components/Search/Search';
import SearchByCity from '../../../components/Search/SearchByCity';
import SearchByPhone from '../../../components/Search/SearchByPhone';
import SearchByState from '../../../components/Search/SearchByState';
import DownloadData from '../../../components/DownloadData';
import FilterModal from '../../../components/FilterModal';
import {
    LOGS_FILTER_DATA_MODEL,
    TRANSACTION_DATA_FILTERS,
    documitraOrderStatusOption as documitraOrderStatus,
    statusOption as photoOrderStatus,
} from '../../../constants/AppConstant';
import callApi from '../../../Api/config';
import {
    APPLICATION_FORM_SERVICES,
    DOCUMITRA_LOGS,
    PHOTO_ORDER_APPLICATION,
    DOCUMITRA_ORDER_TRANSACTION,
    PHOTO_ORDER_TRANSACTION,
} from '../../../constants/EntityName';

interface IDefaultTableHeader {
    headerName?: string;
    totalCount?: number;
    countText?: string;
    setSearchQuery: (value: any) => void;
    setPhoneSearchQuery: (value: any) => void;
    setCitySearchQuery: (value: any) => void;
    setStateSearchQuery: (value: any) => void;
    onAdd: () => void;
    addName?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    showDownload?: boolean;
    entityName: string;
    showFilter?: boolean;
    filterQuery?: (value: any) => void;
    items?: MenuProps['items'];
}

const DefaultTableHeader = (props: IDefaultTableHeader) => {
    const { disableSearch, items } = props;
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [serviceOptions, setServiceOptions] = useState<string[]>([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<any>({});

    const DATA_MODEL = [
        {
            key: 1,
            name: 'Date',
            componentType: 'DATE',
            level: 'date',
        },
        {
            key: 2,
            name: 'Payment Status',
            componentType: 'SELECT',
            level: 'paymentStatus',
            options: [
                { id: '1', value: 'Payment done', name: 'Payment done' },
                { id: '2', value: 'Payment pending', name: 'Payment pending' },
                { id: '3', value: 'Order pending', name: 'Order pending' },
            ],
        },
        {
            key: 3,
            name: 'Order Status',
            componentType: 'SELECT',
            level: 'status',
            options: documitraOrderStatus,
        },
        {
            key: 4,
            name: 'Service Name',
            componentType: 'SELECT',
            level: 'serviceName',
            options: serviceOptions,
        },
    ];

    if (props.entityName === DOCUMITRA_LOGS) {
        DATA_MODEL.length = 0;
        DATA_MODEL.push(...LOGS_FILTER_DATA_MODEL);
    }

    if (props.entityName === DOCUMITRA_ORDER_TRANSACTION || props.entityName === PHOTO_ORDER_TRANSACTION) {
        DATA_MODEL.length = 0;
        DATA_MODEL.push(...TRANSACTION_DATA_FILTERS);
        if (props.entityName === DOCUMITRA_ORDER_TRANSACTION) {
            DATA_MODEL.push({
                key: 4,
                name: 'Service Name',
                componentType: 'SELECT',
                level: 'serviceName',
                options: serviceOptions,
            });
        }
    }

    if (props.entityName === PHOTO_ORDER_APPLICATION) {
        const indexToRemove = DATA_MODEL.findIndex((item) => item.name === 'Service Name');
        if (indexToRemove !== -1) {
            DATA_MODEL.splice(indexToRemove, 1);
        }

        const orderStatusIndex = DATA_MODEL.findIndex((item) => item.name === 'Order Status');
        if (orderStatusIndex !== -1) {
            DATA_MODEL[orderStatusIndex].options = photoOrderStatus;
        }
    }

    let formattedFilters: any = {};
    function formatFilters(selectedFilters: any) {
        Object.keys(selectedFilters).forEach((key) => {
            const value = selectedFilters[key];
            if (typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length > 0) {
                formattedFilters = { ...formattedFilters, ...value };
            } else if (value !== '' && value !== null && value !== undefined) {
                formattedFilters[key] = value;
            }
        });
        return formattedFilters;
    }

    const handleOk = () => {
        const queryParam = formatFilters(selectedFilters);
        setOpenModal(false);

        props.filterQuery && props.filterQuery(queryParam);
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const getSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order' || value === 'Refunds') {
            return 'Order Id';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getPhoneSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'Phone No';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getCitySearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'City Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const getStateSearchPlaceholder = (value: string | undefined) => {
        if (value === 'Order') {
            return 'State Name';
        } else {
            return `${value?.toLowerCase()} name`;
        }
    };

    const handleAddFilterClick = () => {
        setOpenModal(true);
    };

    useEffect(() => {
        const fetchServiceOptions = async () => {
            const res = await callApi(APPLICATION_FORM_SERVICES, 'GET', null, { page: '1', limit: '100' });
            const options =
                res?.data?.data?.results.map((item: any) => ({ id: item.id, value: item.name, name: item.name })) || [];
            setServiceOptions(options);
        };

        fetchServiceOptions();
    }, []);

    return (
        <div className='actionbar'>
            <div className='flex align-bottom'>
                <h2>{props.headerName}</h2>
                <span>
                    {props.totalCount} {props.countText ? props.countText : 'Items'}
                </span>
            </div>
            <div className='flex align-center'>
                {!disableSearch && (
                    <Search
                        setSearchQuery={props.setSearchQuery}
                        placeholderValue={getSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByPhone
                        setPhoneSearchQuery={props.setPhoneSearchQuery}
                        placeholderValue={getPhoneSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {props.headerName === 'Order List' && (
                    <SearchByCity
                        setCitySearchQuery={props.setCitySearchQuery}
                        placeholderValue={getCitySearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}

                {props.headerName === 'Order List' && (
                    <SearchByState
                        setStateSearchQuery={props.setStateSearchQuery}
                        placeholderValue={getStateSearchPlaceholder(props.addName)}
                        leftIconImg={true}
                    />
                )}
                {!props.disableAdd && (
                    <div className='addBtn'>
                        <Button onClick={props.onAdd}>+ Add {props.addName}</Button>
                    </div>
                )}

                {props.showDownload && (
                    <div className='downloadBtn'>
                        <DownloadData entityName={props.entityName} />
                    </div>
                )}

                {props.showFilter && items && (
                    <div>
                        <Button
                            onClick={handleAddFilterClick}
                            style={{
                                margin: '10px',
                                padding: '0px 9px',
                                borderRadius: '4px',
                                backgroundColor: '#586f80',
                                height: '28px',
                                display: 'flex',
                                alignItems: 'center',
                                fontWeight: 600,
                                color: 'white',
                            }}>
                            Add Filters
                        </Button>
                    </div>
                )}

                {(props.headerName === 'Option Type' || props.headerName === 'Collection Type') && (
                    <div className='addBtn'>
                        <Button
                            onClick={() => {
                                setModalVisible(true);
                            }}>
                            Change {props.headerName} position
                        </Button>
                    </div>
                )}
            </div>
            <Modal title={'Select Filters'} visible={openModal} onOk={handleOk} onCancel={handleCancel}>
                <FilterModal
                    setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                    dataModel={DATA_MODEL}
                />
            </Modal>
        </div>
    );
};

DefaultTableHeader.defaultProps = {
    addName: '',
};

export default DefaultTableHeader;
