import { Collapse, Card, Button, Typography } from 'antd';

import { getRole } from '../service/CookieManager';
import { formatIsoDate } from '../utils/functions';

const { Panel } = Collapse;
const { Text } = Typography;

const PaymentDetails = (props: any) => {
    const { paymentDetails, userName, userEmail, formDetails } = props;
    const currentUserRole: any = getRole();

    const handleDownloadForm = (paymentDetails: any) => {
        if (!paymentDetails) {
            return;
        }

        const printWindow = window.open('', '_blank');

        if (printWindow) {
            printWindow.document.write(`<html><head><title>Payment Details</title>`);
            printWindow.document.write('</head><body style="padding: 20px;">');
            printWindow.document.write(`<div style='padding: 20px'> 
                <div>
                    ${userName} Payment Details
                </div>
                <div style='display: flex; justify-content: space-between; font-size: 22px'>
                    <div>
                        <h4>Applicant's Name</h4>
                        <p>${userName ? userName : '-'}</p>
                    </div>
                    <div>
                        <h4>Applicant's Email</h4>
                        <p>${userEmail ? userEmail : '-'}</p>
                    </div>
                </div>
                <div style='display: flex; justify-content: space-between; font-size: 22px'>
                    <div>
                        <h4>Payment Mode</h4>
                        <p>${
                            paymentDetails?.paymentDetails?.provider ? paymentDetails?.paymentDetails?.provider : '-'
                        }</p>
                    </div>
                    <div>
                        <h4>Payment Status</h4>
                        <p>${
                            paymentDetails?.paymentDetails?.paymentStatus
                                ? paymentDetails?.paymentDetails?.paymentStatus
                                : '-'
                        }</p>
                    </div>
                    <div>
                        <h4>Payment Date</h4>
                        <p>${paymentDetails.paymentDate ? paymentDetails.paymentDate : '-'}</p>
                    </div>
                </div>
                <div style='display: flex; justify-content: space-between; font-size: 22px'>
                    <div>
                        <h4>Order Id</h4>
                        <p>${paymentDetails.orderId ? paymentDetails.orderId : '-'}</p>
                    </div>
                    <div>
                        <h4>Tracking Id</h4>
                        <p>${paymentDetails.trackingId ? paymentDetails.trackingId : '-'}</p>
                    </div>
                    <div>
                        <h4>Expected Date</h4>
                        <p>${paymentDetails.expectedDate ? paymentDetails.expectedDate : '-'}</p>
                    </div>
                </div>
            </div>`);

            printWindow.document.write(
                `
                <div style='display: flex; justify-content: center;'>
                    <table border='1' style='width: 100%; border-collapse: collapse;
                    text-align: center; font-size: 24px;'>
                        <tr>
                            <th>Title</th>
                            <th>Details</th>
                        </tr>
                        <tr>
                            <td rowspan='2'>Selected Add Ons</td>
                            <td>Quantity: ${
                                paymentDetails.photoEnhancement?.quantity
                                    ? paymentDetails.photoEnhancement?.quantity
                                    : 'No Add On Selected'
                            }</td>
                        </tr>
                        <tr>
                            <td>Price: ${
                                paymentDetails.photoEnhancement?.price
                                    ? paymentDetails.photoEnhancement?.price
                                    : 'No Add On Selected'
                            }</td>
                        </tr>
                        <tr>
                            <td rowspan='2'>Selected Plan</td>
                            <td>Name: ${
                                paymentDetails.pricingPlan?.title
                                    ? paymentDetails.pricingPlan?.title
                                    : 'No plan selected'
                            }</td>
                        </tr>
                        <tr>
                            <td>Price per member: ${
                                paymentDetails.pricingPlan?.discountedPrice
                                    ? paymentDetails.pricingPlan?.discountedPrice
                                    : 'No plan selected'
                            }</td>
                        </tr>
                        <tr>
                            <td>Selected Package</td>
                            <td>${paymentDetails.packageName ? paymentDetails?.packageName : '-'}</td>
                        </tr>
                        <tr>
                            <td>Applying From</td>
                            <td>${formDetails?.applyingFrom ? formDetails?.applyingFrom : '-'}</td>
                        </tr>
                        <tr>
                            <td>Citizenship</td>
                            <td>${formDetails?.citizenship ? formDetails?.citizenship : '-'}</td>
                        </tr>
                        <tr>
                            <td>Destination</td>
                            <td>${formDetails?.destination ? formDetails?.destination : '-'}</td>
                        </tr>
                        <tr>
                            <td rowspan='2'>Coupon Details</td>
                            <td>Coupon applied: ${
                                paymentDetails.appliedCoupon ? paymentDetails.appliedCoupon : 'No Coupon Applied'
                            }</td>
                        </tr>
                        <tr>
                            <td>Coupon Discount: ${
                                paymentDetails.couponDiscount ? paymentDetails.couponDiscount : 'No Coupon Applied'
                            }</td>
                        </tr>
                    </table>
                </div>
                `,
            );

            printWindow.document.write(
                `
                <div style='display: flex; justify-content: space-between; 
                        border: 0.2px solid gray; border-radius: 5px;
                        margin: 30px 0; padding: 0 10px; font-weight: 700; font-size: 20px;'>
                    <p>Convenience Fee</p>
                    <p>$${paymentDetails.convenienceFee ? paymentDetails.convenienceFee : '-'}</p>
                </div>
                <div style='display: flex; justify-content: space-between; 
                        border: 0.2px solid gray; border-radius: 5px;
                        margin: 30px 0; padding: 0 10px; font-weight: 700; font-size: 20px;'>
                    <p>Paid Amount</p>
                    <p>$${
                        paymentDetails?.paymentDetails?.paymentStatus === 'Payment done'
                            ? paymentDetails?.paymentDetails?.amountPaid
                            : '--'
                    }</p>
                </div>
                `,
            );

            printWindow.document.write('</div></body></html>');

            printWindow.document.close();
            printWindow.print();
        }
    };

    return (
        <div key={paymentDetails?.id} style={{ marginBottom: '20px' }}>
            <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
                <Panel key={paymentDetails?.id} header={`Payment Details:`}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                        <Card
                            style={{
                                marginBottom: '25px',
                                width: '100%',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>Order Id: </div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {paymentDetails?.orderId}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>Tracking Id: </div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {paymentDetails?.trackingId || '--'}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>Expected Date:</div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {' '}
                                        {paymentDetails?.expectedDate || '--'}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '20px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div>Payment Date:</div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {' '}
                                        {paymentDetails?.paymentDate || '--'}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>Payment Status:</div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {' '}
                                        {paymentDetails?.paymentDetails?.paymentStatus || '--'}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>Payment Method:</div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {' '}
                                        {paymentDetails?.paymentDetails?.provider || '--'}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <div>Order Date:</div>
                                    <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                        {' '}
                                        {formatIsoDate(paymentDetails?.orderDate) || '--'}
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div>
                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                                <Card
                                    style={{
                                        marginBottom: '25px',
                                        width: '40%',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Selected Add ons:</div>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            {paymentDetails?.photoEnhancement?.selected && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        marginTop: '10px',
                                                    }}>
                                                    <div>PhotoEnhancement: </div>
                                                    <div style={{ display: 'flex', marginLeft: '50px' }}>
                                                        Quantity:{' '}
                                                        <div
                                                            style={{
                                                                marginLeft: '10px',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                            }}>
                                                            {paymentDetails?.photoEnhancement?.quantity}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginLeft: '50px' }}>
                                                        Price:{' '}
                                                        <div
                                                            style={{
                                                                marginLeft: '10px',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                            }}>
                                                            USD {paymentDetails?.photoEnhancement?.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {paymentDetails?.expedite?.selected && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}>
                                                    <div>Expedite: </div>
                                                    <div style={{ display: 'flex', marginLeft: '50px' }}>
                                                        Price:{' '}
                                                        <div
                                                            style={{
                                                                marginLeft: '10px',
                                                                fontSize: '16px',
                                                                fontWeight: '600',
                                                            }}>
                                                            ${paymentDetails?.expedite?.price}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        {!paymentDetails?.photoEnhancement?.selected &&
                                            !paymentDetails?.expedite?.selected && (
                                                <div
                                                    style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                    No Add On selected
                                                </div>
                                            )}
                                    </div>
                                </Card>
                                <Card
                                    style={{
                                        marginBottom: '25px',
                                        width: '40%',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Selected Plan:</div>
                                        <div style={{ marginTop: '10px', display: 'flex' }}>
                                            Name:{' '}
                                            <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                {paymentDetails?.pricingPlan?.title}
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            Price per member:{' '}
                                            <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                {paymentDetails?.pricingPlan?.discountedPricePerMember ||
                                                paymentDetails?.pricingPlan?.discountedPrice ? (
                                                    <div>
                                                        USD{' '}
                                                        {paymentDetails.pricingPlan.discountedPricePerMember ||
                                                            paymentDetails.pricingPlan.discountedPrice}
                                                    </div>
                                                ) : (
                                                    '--'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                                {paymentDetails?.pickupAddress && (
                                    <Card
                                        style={{
                                            marginBottom: '25px',
                                            width: '40%',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ fontSize: '16px', fontWeight: '600' }}>Pick up Address: </div>
                                            <div style={{ display: 'flex', marginTop: '10px' }}>
                                                Name:{' '}
                                                <div
                                                    style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                    {paymentDetails?.pickupAddress?.serviceType}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                Address:{' '}
                                                <div
                                                    style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                    {paymentDetails?.pickupAddress?.addressLine}
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                Pincode:{' '}
                                                <div
                                                    style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                    {paymentDetails?.pickupAddress?.pinCode}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )}
                                {paymentDetails?.address && (
                                    <Card
                                        style={{
                                            marginBottom: '25px',
                                            width: '40%',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Address:</div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}>
                                            <div>
                                                <div style={{ marginTop: '10px', display: 'flex' }}>
                                                    Address Line:{' '}
                                                    <div style={{ marginLeft: '10px', fontWeight: '600' }}>
                                                        {paymentDetails?.address?.addressLine}
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    City:{' '}
                                                    <div style={{ marginLeft: '10px', fontWeight: '600' }}>
                                                        {paymentDetails?.address?.city}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ marginTop: '10px', display: 'flex' }}>
                                                    State:{' '}
                                                    <div style={{ marginLeft: '10px', fontWeight: '600' }}>
                                                        {paymentDetails?.address?.state}
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    Country:{' '}
                                                    <div style={{ marginLeft: '10px', fontWeight: '600' }}>
                                                        {paymentDetails?.address?.country}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                )}

                                {paymentDetails?.packageName && (
                                    <Card
                                        style={{
                                            marginBottom: '25px',
                                            width: '40%',
                                            borderRadius: '8px',
                                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                        }}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ fontSize: '16px', fontWeight: '600' }}>
                                                    Selected Package:{' '}
                                                </div>
                                                <div
                                                    style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                    {paymentDetails?.packageName}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'space-between',
                                                }}>
                                                {formDetails?.applyingFrom && (
                                                    <div>
                                                        <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                                            Applying From:
                                                        </Text>
                                                        {formDetails?.applyingFrom}
                                                    </div>
                                                )}
                                                {formDetails?.citizenship && (
                                                    <div>
                                                        <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                                            Citizenship:
                                                        </Text>
                                                        {formDetails?.citizenship}
                                                    </div>
                                                )}
                                                {formDetails?.destination && (
                                                    <div>
                                                        <Text style={{ fontSize: '16px', marginRight: '10px' }} strong>
                                                            Destination:
                                                        </Text>
                                                        {formDetails?.destination}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                )}

                                <Card
                                    style={{
                                        marginBottom: '25px',
                                        width: '40%',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ fontSize: '16px', fontWeight: '600' }}>Coupon Details:</div>
                                        {paymentDetails?.appliedCoupon ? (
                                            <>
                                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                                    Coupon applied:{' '}
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>
                                                        {' '}
                                                        {paymentDetails?.appliedCoupon}
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex' }}>
                                                    Coupon Discount:{' '}
                                                    <div
                                                        style={{
                                                            marginLeft: '10px',
                                                            fontSize: '16px',
                                                            fontWeight: '600',
                                                        }}>
                                                        USD {paymentDetails?.couponDiscount}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                                {' '}
                                                No Coupon Applied{' '}
                                            </div>
                                        )}
                                    </div>
                                </Card>
                            </div>
                        </div>
                        {paymentDetails?.paymentDetails && (
                            <Card
                                style={{
                                    marginBottom: '25px',
                                    width: '40%',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div>Convenience Fee:</div>
                                        <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                            USD {paymentDetails?.convenienceFee || '--'}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex' }}>
                                        <div>Paid Amount:</div>
                                        <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                            {' '}
                                            {paymentDetails?.paymentDetails?.paymentStatus === 'Payment done'
                                                ? 'USD ' + paymentDetails?.paymentDetails?.amountPaid
                                                : '--'}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        )}

                        {currentUserRole === 'Accountant' && (
                            <Button
                                style={{
                                    backgroundColor: '#586f80',
                                    color: 'white',
                                    borderRadius: '4px',
                                    padding: '4px 8px 4px 8px',
                                    width: '150px',
                                }}
                                onClick={() => handleDownloadForm(paymentDetails)}>
                                Download
                            </Button>
                        )}
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

export default PaymentDetails;
