import { errorMsg } from '../../constants/messages';

const PackagesFormData = [
    {
        title: 'Packages Details',
        name: 'id',
        label: 'Package Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Package Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'name',
        label: 'Package Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Package Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'plans',
        label: 'Package Plans',
        fieldType: {
            name: 'packagePlanSelection',
            options: [],
        },
        placeholder: 'Click here to select an Package Plan',
        required: true,
        errorMsg: errorMsg,
        isTableHeader: false,
        isAccordian: true,
        readonly: true,
    },
    {
        name: 'planInfo',
        label: 'Plan Info',
        fieldType: {
            name: 'textarea',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Info', columnType: 'planInfo', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'serviceAttributes',
        label: 'Service Attributes',
        fieldType: {
            name: 'serviceAttributeSelection',
        },
        placeholder: 'Click here to select an Service Attributes',
        required: true,
        errorMsg: 'Service attributes required',
        tableMeta: {
            title: 'Service Attributes',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'serviceAttributesDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'documitraFees',
        label: 'Documitra Fees ($)',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: 'Documitra Fees should be in number',
        tableMeta: { title: 'Documitra Fee ($)' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'governmentFees',
        label: 'Government Fees ($)',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: 'Government Fees should be in number',
        tableMeta: { title: 'Government Fee ($)' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'packageInfo',
        label: 'Fee Description',
        fieldType: {
            name: 'textarea',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Fee Description', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'service',
        label: 'Service',
        fieldType: {
            name: 'dynamicShowKeys',
            options: [],
        },
        placeholder: 'Click here to select an Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'serviceName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: true,
    },
    {
        name: 'applyingFrom',
        label: 'ApplyingFrom Country',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select country',
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Applying From',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'applyingFrom',
        },
        isTableHeader: false,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'destination',
        label: 'Destination Country',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select country',
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Destination',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'destination',
        },
        isTableHeader: false,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'citizenship',
        label: 'Citizenship Country',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select country',
        required: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Citizenship',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'citizenship',
        },
        isTableHeader: false,
        isAccordian: true,
        readonly: false,
    },
];

export { PackagesFormData };
