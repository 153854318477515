import { acceptableImageFormat } from '../../constants/FilterDataValues';
import { errorMsg } from '../../constants/messages';

const PhotomitraTakingPhotoStepsFormData = [
    {
        title: 'Taking Photo Step Details',
        name: 'label',
        label: 'Label',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Label', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'details',
        label: 'Details',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Details' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'priority',
        label: 'Priority',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Priority' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'iconImage',
        label: 'Icon Image',
        fieldType: {
            name: 'photomitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { PhotomitraTakingPhotoStepsFormData };
