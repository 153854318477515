import { errorMsg } from '../constants/messages';

const DocumitraLogsFormData = [
    {
        title: 'Logs & History Details',
        name: 'moduleName',
        label: 'Module Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Module Name' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'actionType',
        label: 'Action Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Action Type', enableSort: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'createdAt',
        label: 'Date of Action',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Date of Action', allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'userEmail',
        label: 'User Email',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Email' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'userName',
        label: 'User Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Name' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'userRole',
        label: 'User Role',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Role' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'userPhone',
        label: 'User Phone',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Phone' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { DocumitraLogsFormData };
