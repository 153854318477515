import { acceptableImageFormat } from '../constants/FilterDataValues';
import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_MAX_VALUE } from '../utils/Regexp/Regex';

const HowItWorksFormData = [
    {
        title: 'How It Works Details',
        name: 'label',
        label: 'Label',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Label', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'details',
        label: 'Details',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Details' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'priority',
        label: 'Priority',
        fieldType: {
            name: 'number',
        },
        required: true,
        rules: [{ pattern: REGEX_MAX_VALUE, message: customErrorMsg('priority') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Priority' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'iconImage',
        label: 'Icon Image',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Icon Image', enableSort: false, allowAddRecordClick: false, columnType: 'image' },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { HowItWorksFormData };
