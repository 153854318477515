import { errorMsg } from '../constants/messages';

const couponFormData = [
    {
        title: 'Coupon Details',
        name: 'code',
        label: 'Coupon code',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Coupon code', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'type',
        label: 'Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Flat', value: 'flat' },
                { name: 'Percentage', value: 'percentage' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Type' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'Platform',
        label: 'Platform',
        fieldType: {
            name: 'select',
            options: [
                { name: 'DOCUMITRA', value: 'DOCUMITRA' },
                { name: 'PHOTOMITRA', value: 'PHOTOMITRA' },
                { name: 'REFERRAL', value: 'REFERRAL' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Platform' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'discount',
        label: 'Discount',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Discount' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'validity',
        label: 'Coupon Validity',
        fieldType: {
            name: 'date',
            format: 'YYYY-MM-DD',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Coupon Validity',
            enableSort: false,
            columnType: 'dateAndTime',
        },
        isAccordian: true,
        default: '',
    },
    {
        name: 'maxUsagePerUser',
        label: 'Max Usage Per Customer',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Max Usage Per Customer' },
        isAccordian: true,
        default: '',
    },
    {
        name: 'minOrderValue',
        label: 'Minimum Order Value',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Minimum Order Value' },
        isAccordian: true,
        default: '',
    },
    {
        name: 'CapLimit',
        label: 'Maximum Discount Upto',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Maximum Discount Upto' },
        isAccordian: true,
        default: '',
    },
    {
        name: 'isActive',
        label: 'Is Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        placeholder: '',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Active',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'isPublic',
        label: 'Is Public',
        fieldType: {
            name: 'select',
            options: [
                { name: 'True', value: true },
                { name: 'False', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Is Public',
            enableSort: false,
            columnType: 'active',
        },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { couponFormData };
