/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
// import { useSelector } from 'react-redux';

const { SubMenu } = Menu;

import { AppRoutes, RemoveRolePermission, entityArray } from '../../constants/AppConstant';
import { deleteToken, getRole } from '../../service/CookieManager';

import './styles.scss';

/**
 * returns the key and the parent key for a given url
 */
const getKeys = (url: string, entityArr: any = entityArray) =>
    entityArr.reduce(
        (acc: string, entityObject: any) => {
            if (acc[0] !== '') {
                return acc;
            }

            if (entityObject.children) {
                const a = entityObject.children.find((child: { entityUrl: string }) => child.entityUrl === url);
                return a ? [a.key, entityObject.key] : [''];
            }

            return entityObject.entityUrl === url ? [entityObject.key] : [''];
        },
        [''],
    );

export type EntityArray = {
    key: string;
    entityName: string;
    entityUrl: string;
    moduleName: string;
    children?: EntityArray[];
};

interface SidebarProps {
    title: string;
    logo?: string;
    entityArr: EntityArray[];
}

const Sidebar = (props: SidebarProps) => {
    const { entityArr } = props;
    const history = useHistory();

    const currentUserRole: any = getRole();
    const rolePermission = RemoveRolePermission[currentUserRole];
    const disableModuleList = rolePermission?.disableModule;

    // const modulesList = getModulePermission();

    // const permissionModuleListArray = modulesList ? JSON.parse(modulesList) : [];

    const [activeKey, setActiveKey] = useState<string>(getKeys('/', entityArr)[0]);
    const [openKey, setOpenKey] = useState<string[]>(['']);

    const { pathname } = useLocation();

    const onItemSelect = (event: { keyPath: string[] }) => {
        const [, parentKey] = event.keyPath;

        parentKey && setOpenKey([parentKey]);
    };

    const onTitleClick = (event: { key: string }) => {
        const { key } = event;
        if (openKey.includes(key)) {
            setOpenKey([]);
        } else {
            setOpenKey([key]);
        }
    };

    useEffect(() => {
        const [itemKey, parentKey] = getKeys(pathname);

        setActiveKey(itemKey);
        setOpenKey([parentKey]);
    }, [pathname]);

    const handleChangePassword = () => {
        history.push(AppRoutes.ChangePassword);
    };

    return (
        <div className='sidebar-holder'>
            <div className='sidebar-navigation'>
                <div className='sidebar-header'>
                    <div
                        style={{
                            fontSize: '16px',
                            fontWeight: 'bold',
                            color: '#ffffff',
                            cursor: 'pointer',
                        }}>
                        <span style={{ margin: '0 12px', verticalAlign: 'middle' }}> {props.title}</span>
                    </div>
                </div>

                <Menu mode='inline' activeKey={activeKey} openKeys={openKey} onSelect={onItemSelect} theme='dark'>
                    {entityArr
                        .filter((item: any) => !disableModuleList?.includes(item.entityName))
                        .map(({ key, entityName, entityUrl, children }) => {
                            if (children) {
                                return (
                                    <SubMenu key={key} title={entityName} onTitleClick={onTitleClick}>
                                        {children
                                            // .filter((child: EntityArray) =>
                                            //     permissionModuleListArray.includes(child.moduleName),
                                            // )
                                            .map((child: EntityArray) => (
                                                <Menu.Item key={child.key}>
                                                    <Link to={child.entityUrl}>{child.entityName}</Link>
                                                </Menu.Item>
                                            ))}
                                    </SubMenu>
                                );
                            }
                            return (
                                <Menu.Item key={key}>
                                    <Link to={entityUrl}>{entityName}</Link>
                                </Menu.Item>
                            );
                        })}
                </Menu>
            </div>

            <div className='logout-btn-wrapper'>
                <Button type='text' className={'change-password-btn'} block onClick={handleChangePassword}>
                    Change Password
                </Button>

                <Button
                    type='text'
                    className={'logout-button'}
                    block
                    onClick={() => {
                        deleteToken();
                        window.location.reload();
                    }}>
                    <PoweroffOutlined /> {'Logout'}
                </Button>
            </div>
        </div>
    );
};

export default Sidebar;
