import { useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

import './style.scss';

import { InputInterface } from '../utils/forms/FormInterface';
import { inputTypesOptions } from '../constants/AppConstant';

interface ValidationInputProps {
    formInput: InputInterface;
    setFormData?: any;
    form?: any;
    index?: number;
    isCreateMode?: boolean;
    setHide?: any;
    // eslint-disable-next-line no-unused-vars
    setDynamicValidation?: (value: any) => void;
    setHideCron?: any;
    setHideMaxAMount?: any;
    dynamicValidation?: any;
}
const MultipleTwinInputforValidation = (props: ValidationInputProps) => {
    const { formInput, index, dynamicValidation } = props;
    const [type, setType] = useState<any>([]);

    const handleValidationOptionChange = (fieldsIndex: number) => (value: any) => {
        const types = [...type];
        types[fieldsIndex] = value;
        setType([...types]);
    };

    const {
        // eslint-disable-next-line no-unused-vars
        fieldType: { options },
    } = formInput;

    const ValueInputs = ['length', 'min', 'max', 'matches', 'trim', 'lessThan', 'moreThan', 'maxSize', 'fileTypes'];

    const filteredData = inputTypesOptions.filter((item: any) => item.value === dynamicValidation);

    return (
        <Form.List key={index} name={formInput.name}>
            {(fields, { add, remove }, { errors }) => (
                <>
                    {fields.map(({ key, name, ...restField }, fieldsIndex) => (
                        <Form.Item
                            key={key}
                            wrapperCol={{ span: 8, offset: 4 }}
                            labelCol={{ span: 4 }}
                            label={
                                fieldsIndex === 0 ? (
                                    <div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>
                                ) : (
                                    <div></div>
                                )
                            }
                            labelAlign='left'
                            colon={false}>
                            <div
                                style={{
                                    columnGap: '8px',
                                }}>
                                {formInput.fieldType.inputOption === 'option' ? (
                                    <>
                                        <Form.Item
                                            style={{ marginBottom: '25px', width: '100%' }}
                                            {...restField}
                                            name={[name, 'type']}
                                            rules={[{ required: true, message: 'Missing name' }]}>
                                            <Select
                                                placeholder={'select type'}
                                                disabled={formInput.readonly}
                                                allowClear
                                                showSearch
                                                optionFilterProp='children'
                                                onChange={handleValidationOptionChange(fieldsIndex)}
                                                style={{ width: '100%' }}>
                                                {filteredData[0]?.validations.map((obj: any, validationIdx: number) => (
                                                    <Option key={validationIdx} value={obj.type} name={obj.name}>
                                                        {obj.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </>
                                ) : (
                                    <Form.Item
                                        style={{ marginBottom: '25px', width: '100%' }}
                                        {...restField}
                                        name={[name, 'value1']}
                                        rules={[{ required: false, message: 'Missing this value' }]}>
                                        <Input
                                            placeholder={formInput.placeholder}
                                            disabled={false}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                )}

                                {ValueInputs.includes(type[fieldsIndex]) && (
                                    <Form.Item
                                        style={{ marginBottom: '25px', width: '100%' }}
                                        {...restField}
                                        name={[name, 'value']}
                                        rules={[
                                            {
                                                required: ValueInputs.includes(type[fieldsIndex]),
                                                message: 'Missing value',
                                            },
                                        ]}>
                                        <Input
                                            placeholder='value'
                                            disabled={formInput.readonly}
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    style={{ marginBottom: '0px', width: '100%' }}
                                    {...restField}
                                    name={[name, 'errorMessage']}
                                    rules={[{ required: true, message: 'Missing quantity' }]}>
                                    <Input
                                        placeholder='Error Message'
                                        disabled={formInput.readonly}
                                        style={{ width: '100%' }}
                                    />
                                </Form.Item>
                                {!formInput.readonly && (
                                    <MinusCircleOutlined
                                        className='dynamic-delete-button offset'
                                        onClick={() => remove(name)}
                                    />
                                )}
                            </div>
                        </Form.Item>
                    ))}
                    {!formInput.readonly && (
                        <Form.Item wrapperCol={{ offset: 8 }} labelCol={{ span: 4 }}>
                            <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                Add Validation
                            </Button>
                            <Form.ErrorList errors={errors} />
                        </Form.Item>
                    )}
                </>
            )}
        </Form.List>
    );
};

export default MultipleTwinInputforValidation;
