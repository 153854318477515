import { Typography } from 'antd';

const { Text } = Typography;

const InformtionNote = () => (
    <>
        <Text style={{ fontSize: '14px', color: 'red' }}>Note: </Text>
        <Text style={{ fontSize: '12px', color: 'red' }}>
            Once the question is deleted, It will be automatically removed from all the forms.
        </Text>
    </>
);

export default InformtionNote;
