import { Card, Collapse } from 'antd';

const { Panel } = Collapse;

const UserDetails = (props: any) => {
    const { userDetails } = props;
    return (
        <div key={userDetails?.id} style={{ marginBottom: '10px' }}>
            <Collapse expandIconPosition={'right'} defaultActiveKey={['1']}>
                <Panel key={userDetails?.id} header={`User Details:`}>
                    <Card
                        style={{
                            marginBottom: '25px',
                            width: '80%',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex' }}>
                                <div>Name:</div>
                                <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                    {userDetails?.name}
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>Phone:</div>
                                <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                    {userDetails?.phone}
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>Email:</div>
                                <div style={{ marginLeft: '10px', fontSize: '16px', fontWeight: '600' }}>
                                    {userDetails?.email}
                                </div>
                            </div>
                        </div>
                    </Card>
                </Panel>
            </Collapse>
        </div>
    );
};

export default UserDetails;
