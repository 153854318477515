import { errorMsg } from '../../constants/messages';
import { fileFormatOptions } from '../Stores';

const FormPackageDocumentsFormData = [
    {
        title: 'Documents Details',
        name: 'name',
        label: 'Document Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Documents Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'fileFormats',
        label: 'File Format',
        fieldType: {
            name: 'multiSelect',
            options: fileFormatOptions,
        },
        placeholder: 'Click here to select an file format',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'File Format',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'fileFormats',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'maxFiles',
        label: 'Max Files',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Max Files' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'category',
        label: 'Age Group',
        fieldType: {
            name: 'select',
            options: [
                { id: 1, name: 'Both', value: 'Both' },
                { id: 2, name: 'Adult', value: 'Adult' },
                { id: 3, name: 'Minor', value: 'Minor' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Age Group' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { FormPackageDocumentsFormData };
