import { useEffect, useState } from 'react';
import { Card, Collapse, FormInstance, Image, Space, Button, Typography } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import { InputInterface } from '../utils/forms/FormInterface';
import PhotoUploadModal from './PhotoUploadModal';
import { fallback } from '../constants/FallbackImage';
import callApi from '../Api/config';
import { ORDER_USER } from '../constants/EntityName';
import UserDetails from './UserDetails';
import PaymentDetails from './PaymentDetails';
import { getRole } from '../service/CookieManager';
import { BASE_IMAGE_URL } from '../env';

const { Panel } = Collapse;
const { Title } = Typography;

interface revieworderProps {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    photoDetails?: any;
}

const ReviewPhotoOrder = (props: revieworderProps) => {
    const { photoDetails } = props;
    const [formDetails, setFormDetails] = useState<any>({});
    const [activeItem, setActiveItem] = useState<any>({});
    const [itemNumber, setItemNumber] = useState<number>();
    const [photoType, setPhotoType] = useState<string>('');
    const [paymentDetails, setPaymentDetails] = useState<any>({});
    const [userDetails, setUserDetails] = useState<any>({});
    const [openPhotoUploadModal, setOpenPhotoUploadModal] = useState<boolean>(false);
    const { items } = formDetails || [];
    const onDownload = async (fileUrl: any) => {
        if (fileUrl) {
            window.open(fileUrl?.startsWith('https') || !fileUrl ? fileUrl : `${BASE_IMAGE_URL}/${fileUrl}`);
        }
    };

    const getUserPhotos = (item: any) => {
        const photoArray = ['photo1', 'photo2', 'photo3', 'photo4'];

        return (
            <div style={{ display: 'flex', gap: '10px', flex: 'wrap' }}>
                {photoArray?.map((photo: any) => (
                    <>
                        <div
                            key={item?.id}
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                gap: '4px',
                                padding: '6px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                position: 'relative',
                            }}>
                            <Image
                                width={200}
                                src={
                                    item[photo]?.url?.startsWith('https') || !item[photo]?.url
                                        ? item[photo]?.url
                                        : `${BASE_IMAGE_URL}/${item[photo]?.url}`
                                }
                                fallback={fallback}
                            />
                            <DownloadOutlined
                                style={{
                                    position: 'absolute',
                                    fontSize: 18,
                                    bottom: '8px',
                                    right: '8px',
                                    padding: '4px',
                                    cursor: 'pointer',
                                    backgroundColor: '#00000070',
                                    borderRadius: '4px',
                                    color: 'white',
                                }}
                                onClick={() => onDownload(item[photo]?.url)}
                            />
                        </div>
                    </>
                ))}
            </div>
        );
    };

    const handlePhotoModal = (typeOfPhoto: string, item: any, itemNumber: number) => () => {
        setPhotoType(typeOfPhoto);
        setActiveItem(item);
        setItemNumber(itemNumber);
        setOpenPhotoUploadModal(!openPhotoUploadModal);
    };

    const getUser = async (userId: string) => {
        try {
            const entityData: any = await callApi(`${ORDER_USER}/${userId}`, 'GET');
            const { isSuccess, data } = entityData;
            if (!isSuccess) {
                // eslint-disable-next-line no-console
                console.error('Something went wrong, please try again');
            }
            setUserDetails(data?.data);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Something went wrong, please try again');
        }
    };

    const handleDownloadAll = (item: any) => {
        const photoArray = ['photo1', 'photo2', 'photo3', 'photo4'];

        const downloadWithDelay = async (url: string, filename: string) => {
            const a = document.createElement('a');
            a.href = url?.startsWith('https') || !url ? url : `${BASE_IMAGE_URL}/${url}`;
            a.download = filename;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        };

        const getFileExtension = (url: string) => {
            const parts = url.split('.');
            return parts[parts.length - 1].toLowerCase();
        };

        const downloadAll = async () => {
            for (let i = 0; i < photoArray.length; i++) {
                const photoUrl =
                    item[photoArray[i]]?.url?.startsWith('https') || !item[photoArray[i]]?.url
                        ? item[photoArray[i]]?.url
                        : `${BASE_IMAGE_URL}/${item[photoArray[i]]?.url}`;
                if (photoUrl) {
                    try {
                        let filename = `photo${i + 1}`;
                        const extension = getFileExtension(photoUrl);
                        if (extension === 'jpg' || extension === 'png' || extension === 'pdf') {
                            filename += `.${extension}`;
                        }
                        await downloadWithDelay(photoUrl, filename);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error('Download failed for:', photoUrl, error);
                    }
                }
            }
        };

        downloadAll();
    };

    useEffect(() => {
        if (!photoDetails) {
            return;
        }
        const payment = {
            id: photoDetails?.id,
            expedite: photoDetails?.expedite, //price
            photoEnhancement: photoDetails?.photoEnhancement, //price
            pricingPlan: photoDetails?.pricingPlan, //discounted price
            convenienceFee: photoDetails?.convenienceFee,
            payableOfferPrice: photoDetails?.payableOfferPrice, // totalDiscountedprice + convenience fees
            orderId: photoDetails?.orderId,
            trackingId: photoDetails?.trackingId,
            pickupAddress: photoDetails?.pickupAddress,
            expectedDate: photoDetails?.expectedDate,
            appliedCoupon: photoDetails?.appliedCoupon,
            couponDiscount: photoDetails?.couponDiscount,
            paymentDate: photoDetails?.paymentDetails?.paymentDate,
            paymentMethod: photoDetails?.paymentDetails?.paymentMethod,
            orderDate: photoDetails?.createdAt,
            paymentDetails: formDetails?.paymentDetails || {},
            address: photoDetails?.address,
        };
        getUser(photoDetails?.userId);
        setPaymentDetails(payment);
        setFormDetails(photoDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [photoDetails]);

    const currentUserRole: any = getRole();

    return (
        <>
            <UserDetails userDetails={userDetails} />
            <PaymentDetails
                formDetails={photoDetails}
                paymentDetails={paymentDetails}
                userName={userDetails.name}
                userEmail={userDetails.email}
            />
            {currentUserRole !== 'Accountant' && (
                <Collapse style={{ marginBottom: '20px' }} expandIconPosition={'right'} defaultActiveKey={['1']}>
                    <Panel key={formDetails?.id} header={`User Uploads:`}>
                        {items?.map((item: any, itemNumber: number) => (
                            <Card
                                key={item?.id}
                                style={{
                                    marginBottom: '25px',
                                    width: '100%',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                }}>
                                <Collapse expandIconPosition={'left'} defaultActiveKey={['1']}>
                                    <Panel key={item?.id} header={`User: ${itemNumber + 1}`}>
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '10px',
                                            }}>
                                            <Title level={5}>Photos:</Title>
                                            <Space
                                                style={{
                                                    marginTop: '4px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: '#586f80',
                                                        color: 'white',
                                                        borderRadius: '4px',
                                                        padding: '4px 8px 4px 8px',
                                                    }}
                                                    onClick={handlePhotoModal(
                                                        'Processed Grid Photo',
                                                        item,
                                                        itemNumber,
                                                    )}>
                                                    + Add Processed Photo
                                                </Button>
                                            </Space>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            {getUserPhotos(item)}
                                            <Button
                                                style={{
                                                    backgroundColor: '#586f80',
                                                    color: 'white',
                                                    borderRadius: '4px',
                                                    padding: '4px 8px 4px 8px',
                                                }}
                                                onClick={() => handleDownloadAll(item)}>
                                                Download All Documents
                                            </Button>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </Card>
                        ))}
                    </Panel>
                </Collapse>
            )}
            <PhotoUploadModal
                photoType={photoType}
                activeItem={activeItem}
                itemNumber={itemNumber}
                formDetails={formDetails}
                openPhotoUploadModal={openPhotoUploadModal}
                setOpenPhotoUploadModal={setOpenPhotoUploadModal}
            />
        </>
    );
};

export default ReviewPhotoOrder;
