import { useEffect, useState } from 'react';
import { Form, FormInstance, Select } from 'antd';

import callApi from '../Api/config';
import { COUNTRY } from '../constants/EntityName';
import { InputInterface } from '../utils/forms/FormInterface';
interface propTypes {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}

const { Option } = Select;

type AllKeyValuePairType = { id: string; value: string; name: string }[];

const parametersTypeOptions: AllKeyValuePairType = [
    { id: '1', value: 'applyingFrom', name: 'Applying From' },
    { id: '2', value: 'destination', name: 'Destination' },
    { id: '3', value: 'citizenship', name: 'Citizenship' },
];

const DynamicCountrySelect = (props: propTypes) => {
    const { formDetails, form, formInput } = props;
    const [activeparameters, setActiveParameters] = useState<any>([]);
    const [parameterCountries, setParametersContries] = useState<any>({
        applyingFrom: [],
        destination: [],
        citizenship: [],
    });
    const [allCountries, setAllCountries] = useState<any>([]);

    const handleActiveParameter = (value: any) => {
        setActiveParameters(value);
    };

    const handleParameterCountries = (name: any) => (value: any) => {
        if (value.includes('all')) {
            const selectedCountries = allCountries.map((country: any) => country?.value);
            form.setFieldValue(name, [...selectedCountries]);
            return;
        }
    };

    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const entityData: any = await callApi(`${COUNTRY}`, 'GET');
                // Assuming entityData is an array of country names
                const countryNames: string[] = entityData?.data?.data;

                // Convert array of strings to array of objects with 'value' and 'name' with 'uniqueId' properties
                const countryData = countryNames.map((countryName, index) => ({
                    id: index + 1,
                    value: countryName,
                    name: countryName,
                }));

                if (countryData) {
                    setAllCountries(countryData);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching countries list:', error);
                setAllCountries([]); // Set an empty array in case of an error
            }
        };

        fetchCountries();
    }, []);

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        if (formDetails?.parameters) {
            setActiveParameters([...formDetails.parameters]);
            form.setFieldValue('parameters', formDetails?.parameters);
        }
        if (formDetails?.applyingFrom) {
            setParametersContries((prevState: any) => ({ ...prevState, applyingFrom: formDetails?.applyingForm }));
            form.setFieldValue('applyingFrom', formDetails?.applyingFrom);
        }
        if (formDetails?.destination) {
            setParametersContries((prevState: any) => ({ ...prevState, destination: formDetails?.destination }));
            form.setFieldValue('destination', formDetails?.destination);
        }
        if (formDetails?.citizenship) {
            setParametersContries((prevState: any) => ({ ...prevState, citizenship: formDetails?.citizenship }));
            form.setFieldValue('citizenship', formDetails?.citizenship);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDetails]);

    return (
        <>
            <Form.Item
                name={'parameters'}
                label='Select Parameters'
                wrapperCol={{ span: 8, offset: 4 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <Select
                    id='parameters'
                    mode='multiple'
                    disabled={!props.isCreateMode}
                    placeholder='Select'
                    defaultValue={activeparameters}
                    showSearch
                    optionFilterProp='children'
                    onChange={handleActiveParameter}
                    style={{ width: '100%' }}>
                    {parametersTypeOptions?.map((obj: any) => (
                        <Option key={obj?.id} value={obj?.value} name={obj?.name} selected>
                            {obj?.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {activeparameters.includes('applyingFrom') && (
                <Form.Item
                    name={'applyingFrom'}
                    label='Applying From'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        id='applyingFrom'
                        mode='multiple'
                        placeholder='Select'
                        defaultValue={parameterCountries?.applyingFrom}
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        onChange={handleParameterCountries('applyingFrom')}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {allCountries?.map((obj: any) => (
                            <Option key={obj?.id} value={obj?.value} name={obj?.name} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {activeparameters.includes('destination') && (
                <Form.Item
                    name={'destination'}
                    label='Destination'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        id='destination'
                        mode='multiple'
                        placeholder='Select'
                        defaultValue={parameterCountries?.destination}
                        value={parameterCountries?.destination}
                        showSearch
                        optionFilterProp='children'
                        onChange={handleParameterCountries('destination')}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {allCountries?.map((obj: any) => (
                            <Option key={obj?.id} value={obj?.value} name={obj?.name} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
            {activeparameters.includes('citizenship') && (
                <Form.Item
                    name={'citizenship'}
                    label='Citizenship'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        id='citizenship'
                        mode='multiple'
                        placeholder='Select'
                        defaultValue={parameterCountries?.citizenship}
                        value={parameterCountries?.citizenship}
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        onChange={handleParameterCountries('citizenship')}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {allCountries?.map((obj: any) => (
                            <Option key={obj?.id} value={obj?.value} name={obj?.name} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
        </>
    );
};

export default DynamicCountrySelect;
