// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
  text-align: right;
  display: flex;
  align-items: center; }
  .mainContainer .rangeContainer {
    margin-top: 22px; }
    .mainContainer .rangeContainer p {
      margin-bottom: 0;
      text-align: center; }
  .mainContainer .formDownloadBtn {
    background: #586f80;
    border-radius: 4px;
    height: 28px;
    color: white;
    font-weight: 600;
    text-align: center;
    border: none !important;
    transition: all 0.5s linear;
    margin: 0px 10px;
    padding: 0 7px;
    cursor: pointer; }
`, "",{"version":3,"sources":["webpack://./src/components/DownloadData/style.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,aAAa;EACb,mBAAmB,EAAA;EAHvB;IAMQ,gBAAgB,EAAA;IANxB;MASY,gBAAgB;MAChB,kBAAkB,EAAA;EAV9B;IAeQ,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;IACvB,2BAA2B;IAC3B,gBAAgB;IAChB,cAAc;IACd,eAAe,EAAA","sourcesContent":[".mainContainer {\n    text-align: right;\n    display: flex;\n    align-items: center;\n\n    .rangeContainer {\n        margin-top: 22px;\n\n        p {\n            margin-bottom: 0;\n            text-align: center;\n        }\n    }\n\n    .formDownloadBtn {\n        background: #586f80;\n        border-radius: 4px;\n        height: 28px;\n        color: white;\n        font-weight: 600;\n        text-align: center;\n        border: none !important;\n        transition: all 0.5s linear;\n        margin: 0px 10px;\n        padding: 0 7px;\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
