import { Button, Card, Form, FormInstance } from 'antd';

import { useEffect, useState } from 'react';

import { InputInterface } from '../utils/forms/FormInterface';
import SubTitleModal from './SubTitleModal';

interface processProps {
    formInput: InputInterface;
    index: number;
    parentForm: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}

const PackageProcess = (props: processProps) => {
    const { isCreateMode, parentForm, formDetails } = props;
    const [form] = Form.useForm();
    const [currentSubTitle, setCurrentSubTitle] = useState<any>(null);
    const [subTitles, setSubTitles] = useState<any>([]);
    const [openSubTitleModal, setOpenSubTitleModal] = useState<boolean>(false);

    const handleDeleteSubTitles = (index: number) => {
        const updatedSubTitles = subTitles;
        updatedSubTitles.splice(index, 1);
        setSubTitles(() => [...updatedSubTitles]);
        parentForm.setFieldValue('subtitle', subTitles);
        form.setFieldValue(`${index}-heading`, '');
        form.setFieldValue(`${index}-description`, '');
        form.setFieldValue(`${index}-sequence`, '');
        setCurrentSubTitle(null);
    };

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        setSubTitles(formDetails?.subtitle);
        parentForm.setFieldValue('subtitle', formDetails?.subtitle);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDetails]);

    return (
        <div>
            <Form.Item
                name={'subtitle'}
                label='Add Sub Headings'
                wrapperCol={{ span: 8, offset: 4 }}
                labelCol={{ span: 4 }}
                labelAlign='left'>
                <Card
                    key={1}
                    style={{
                        marginBottom: '25px',
                        width: '566px',
                        borderRadius: '8px',
                        border: 'solid 1px #d9d9d9',
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {subTitles?.map((subTitle: any, index: number) => (
                                <div
                                    style={{
                                        padding: '4px',
                                        cursor: 'pointer',
                                        backgroundColor: '#80808029',
                                        marginBottom: '2px',
                                        marginLeft: '4px',
                                        inlineSize: 'fit-content',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    key={subTitle?.id}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '7.5px',
                                        }}
                                        onClick={() => {
                                            setCurrentSubTitle(index);
                                            setOpenSubTitleModal(!openSubTitleModal);
                                        }}>
                                        {subTitle?.heading}
                                    </div>
                                    <div
                                        style={{
                                            marginLeft: '6px',
                                            height: '20px',
                                            width: '15px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                        onClick={() => handleDeleteSubTitles(index)}>
                                        X
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button
                                style={{
                                    backgroundColor: '#586f80',
                                    color: 'white',
                                    borderRadius: '4px',
                                    border: 'none',
                                    marginRight: '4px',
                                }}
                                onClick={() => setOpenSubTitleModal(!openSubTitleModal)}>
                                + Add Sub Headings
                            </Button>
                        </div>
                    </div>
                </Card>
            </Form.Item>
            <SubTitleModal
                subTitles={subTitles}
                isCreateMode={isCreateMode}
                setSubTitles={setSubTitles}
                openSubTitleModal={openSubTitleModal}
                currentSubTitle={currentSubTitle}
                parentForm={parentForm}
                childFrom={form}
                setCurrentSubTitle={setCurrentSubTitle}
                setOpenSubTitleModal={setOpenSubTitleModal}
            />
        </div>
    );
};

export default PackageProcess;
