/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { useState } from 'react';
import { Checkbox, DatePicker, Input, Layout, Menu, Select } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { Content } from 'antd/lib/layout/layout';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;

import './style.scss';

interface FilterOption {
    key: number;
    componentType: 'SELECT' | 'DATE' | 'INPUT' | 'DOB';
    level: string;
    options?: { id: string; value: string; name: string }[];
}

const FilterModal = ({ setSelectedFilters, selectedFilters, dataModel }: any) => {
    const [currentContent, setCurrentContent] = useState<number>(0);
    const [checkedItems, setCheckedItems] = useState<Record<number, boolean>>({});

    const toggleCheckbox = (key: number, checked: boolean) => {
        setCheckedItems((prevCheckedItems) => {
            const updatedCheckedItems = { ...prevCheckedItems };
            updatedCheckedItems[key] = checked;

            const updatedFilters = { ...selectedFilters };
            if (!checked) {
                const deletedKey = dataModel.find((item: any) => item.key === key)?.level;
                delete updatedFilters[deletedKey];
            }

            setSelectedFilters(updatedFilters);
            return updatedCheckedItems;
        });
    };

    const handleSelect = (filterKey: string, value: any) => {
        if (filterKey === 'date' && value && value.length === 2) {
            const startDate = moment(value[0]).startOf('day').format('YYYY-MM-DD');
            const endDate = moment(value[1]).endOf('day').format('YYYY-MM-DD');
            setSelectedFilters({
                ...selectedFilters,
                date: { startDate, endDate },
            });
        } else if (filterKey === 'dob') {
            const dob = moment(value).startOf('day').format('YYYY-MM-DD');
            setSelectedFilters({
                ...selectedFilters,
                dateOfBirth: dob,
            });
        } else {
            setSelectedFilters({ ...selectedFilters, [filterKey]: value });
        }
    };

    const disabledDate = (current: moment.Moment | null) => (current ? current > moment().endOf('day') : false);

    const renderComponent = (option: FilterOption, index: number) => {
        switch (option.componentType) {
            case 'SELECT':
                return (
                    <Select
                        key={index}
                        value={selectedFilters[option.level]}
                        placeholder=''
                        allowClear
                        showSearch
                        onChange={(values) => handleSelect(option.level, values)}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        {option.options?.map((opt) => (
                            <Option key={opt.id} value={opt.value} name={opt.name}>
                                {opt.name}
                            </Option>
                        ))}
                    </Select>
                );
            case 'DATE':
                return (
                    <RangePicker
                        key={index}
                        value={[
                            selectedFilters?.date?.startDate ? moment(selectedFilters.date.startDate) : null,
                            selectedFilters?.date?.endDate ? moment(selectedFilters.date.endDate) : null,
                        ]}
                        onChange={(dates) => handleSelect('date', dates)}
                        disabledDate={disabledDate}
                    />
                );
            case 'DOB':
                return (
                    <DatePicker
                        key={index}
                        value={selectedFilters?.dateOfBirth ? moment(selectedFilters?.dateOfBirth) : null}
                        onChange={(dob) => handleSelect('dob', dob)}
                        disabledDate={disabledDate}
                    />
                );
            case 'INPUT':
                return (
                    <Input
                        key={index}
                        value={selectedFilters[option.level]}
                        placeholder={`Enter ${option?.level}`}
                        onChange={(e) => handleSelect(option.level, e.target.value)}
                    />
                );
            default:
                return null;
        }
    };

    const menuItems = dataModel.map((option: any, index: number) => ({
        label: (
            <>
                <Checkbox
                    checked={checkedItems[option.key]}
                    onChange={(e) => toggleCheckbox(option.key, e.target.checked)}
                />{' '}
                {option.name}
            </>
        ),
        key: index,
        onClick: () => setCurrentContent(option.key),
    }));

    const filteredContent = dataModel
        .filter((option: any, index: any) => checkedItems[option.key])
        .map((option: any, index: number) => renderComponent(option, option.key))
        .filter((item: any) => item.key == currentContent);

    return (
        <Layout>
            <Sider width={180}>
                <Menu mode='inline' items={menuItems} />
            </Sider>
            <Content>{filteredContent}</Content>
        </Layout>
    );
};

export default FilterModal;
