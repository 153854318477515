/* eslint-disable no-console */
import { useState } from 'react';

import { Button, Form } from 'antd';

import PlanCreationModal from './PlanCreationModal';

import { Plans } from './planTypes';

const defaultPlanDetails = {
    title: '',
    price: null,
    discountedPrice: null,
    documents: [],
    features: [],
};

const PackagePlanSelection = (props: any) => {
    const { additionalData, form, packageDetails, isCreateMode } = props;
    const [showPlanModal, setShowPlanModal] = useState<boolean>(false);
    const [onlineConsultationDetails, setOnlineConsultationDetails] = useState<any>({
        ...defaultPlanDetails,
        title: Plans.ONLINE_CONSULTATION,
    });
    const [readyToShipDetails, setReadyToShipDetails] = useState<any>({
        ...defaultPlanDetails,
        title: Plans.READY_TO_SHIP,
    });
    const [selectedPlans, setSelectedPlans] = useState<string[]>(['', '']);

    const [typeOfPlan, setTypeOfPlan] = useState<string>('');

    return (
        <>
            <Form.Item
                name={'plans'}
                label='Update Plans'
                style={{ marginBottom: '25px', width: '100%' }}
                rules={[
                    {
                        required: false,
                        message: 'Please update plans',
                    },
                ]}>
                <div style={{ display: 'flex', marginLeft: '28.2%', justifyContent: 'flex-start', gap: '20px' }}>
                    <Button
                        style={{
                            border: `1px dashed ${selectedPlans[0] ? '#57ff57' : 'red'}`,
                            color: `${selectedPlans[0] ? '#57ff57' : 'red'}`,
                        }}
                        onClick={() => {
                            setTypeOfPlan(Plans.ONLINE_CONSULTATION);
                            setShowPlanModal(!showPlanModal);
                        }}>
                        {Plans.ONLINE_CONSULTATION}
                    </Button>
                    <Button
                        style={{
                            border: `1px dashed ${selectedPlans[1] ? '#57ff57' : 'red'}`,
                            color: `${selectedPlans[1] ? '#57ff57' : 'red'}`,
                        }}
                        onClick={() => {
                            setTypeOfPlan(Plans.READY_TO_SHIP);
                            setShowPlanModal(!showPlanModal);
                        }}>
                        {Plans.READY_TO_SHIP}
                    </Button>
                    <PlanCreationModal
                        additionalData={additionalData}
                        onlineConsultationDetails={onlineConsultationDetails}
                        setOnlineConsultationDetails={setOnlineConsultationDetails}
                        readyToShipDetails={readyToShipDetails}
                        setReadyToShipDetails={setReadyToShipDetails}
                        typeOfPlan={typeOfPlan}
                        showPlanModal={showPlanModal}
                        setShowPlanModal={setShowPlanModal}
                        packageForm={form}
                        isCreateMode={isCreateMode}
                        packageDetails={packageDetails}
                        selectedPlans={selectedPlans}
                        setSelectedPlans={setSelectedPlans}
                    />
                </div>
            </Form.Item>
        </>
    );
};

export default PackagePlanSelection;
