import { errorMsg } from '../constants/messages';

type StatusType = { id: string; value: string; name: string }[];

export const statusOption: StatusType = [
    { id: '1', value: 'Branch Office', name: 'Branch Office' },
    { id: '2', value: 'Data Center', name: 'Data Center' },
];

const locationTypesFormData = [
    {
        name: 'name',
        label: 'Location Type',
        fieldType: {
            name: 'select',
            options: statusOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Location Type', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { locationTypesFormData };
