import { message } from 'antd';

const toastSuccess = (msg = 'This is a success message') => {
    message.success(msg);
};

const toastError = (msg = 'Something went wrong. Please try again..!!') => {
    message.error(msg);
};

const toastEarning = (msg = 'Warning...!!!') => {
    message.warning(msg);
};

export { toastSuccess, toastError, toastEarning };
