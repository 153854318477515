/* eslint-disable no-unused-vars */

// The size specified is in KB.
export enum MaxFileSize {
    Mobile = 50,
    Tablet = 100,
    Desktop = 200,
    SVGImage = 10,
}

export enum AspectRatio {
    VariantMobile = 375 / 250,
    VariantTablet = 375 / 250,
    VariantDesktop = 572 / 368,
    BannerMobile = 2 / 1,
    BannerTablet = 2 / 1,
    BannerDesktop = 117 / 46,
    RecipeHomepage = 80 / 35,
    OfferImageMobile = 9 / 12,
    OfferImageDesktop = 250 / 195,
}

export const acceptableImageFormat = 'image/png, image/jpeg, image/jpg, video/mp4, video/avi ';

// export const acceptableImageWithVideoFormat =
//     'image/png, image/jpeg, image/jpg, video/mp4, video/avi, video/mov, video/m4v, video/m4p, video/amv, video/avc, ';
