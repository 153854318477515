import { Image } from 'antd';

import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

import Title from 'antd/lib/typography/Title';

import { uploadImageApiCall } from '../Api/config';
import { toastError } from '../service/Toast';
import { BASE_IMAGE_URL } from '../env';

const ImageDragDropContainer = (props: any) => {
    const { photoType, photoDetails, setPhotoDetails, activeItem, fallbackImage } = props;

    const handleChange = async (e: any) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);

        try {
            const res: any = await uploadImageApiCall(`/photo-orders/upload`, formData);
            const { data, isSuccess } = res;
            if (isSuccess) {
                setPhotoDetails(data);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const onDownload = async (fileUrl: any) => {
        if (fileUrl) {
            window.open(fileUrl);
        }
    };

    return (
        <div key={`${activeItem}-${photoType}`} style={{ width: '100%' }}>
            <Title level={5}>{`${photoType}:`}</Title>
            {photoDetails?.url ? (
                <div style={{ position: 'relative' }}>
                    <Image
                        width={470}
                        src={
                            photoDetails?.url?.startsWith('https') || !photoDetails?.url
                                ? photoDetails?.url
                                : `${BASE_IMAGE_URL}/${photoDetails?.url}`
                        }
                        fallback={fallbackImage}
                    />
                    <div
                        style={{
                            position: 'absolute',
                            bottom: '4px',
                            right: '4px',
                            display: 'flex',
                            gap: '20px',
                        }}>
                        <DeleteOutlined
                            style={{
                                backgroundColor: '#00000070',
                                padding: '4px',
                                borderRadius: '4px',
                                fontSize: 20,
                                color: 'white',
                            }}
                            onClick={() => setPhotoDetails({})}
                        />
                        <DownloadOutlined
                            style={{
                                backgroundColor: '#00000070',
                                borderRadius: '4px',
                                padding: '4px',
                                fontSize: 20,
                                color: 'white',
                            }}
                            onClick={() => onDownload(photoDetails?.url)}
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <input
                        onChange={(e) => handleChange(e)}
                        type='file'
                        id='avatar'
                        name='avatar'
                        accept='image/png, image/jpeg'
                    />
                </div>
            )}
        </div>
    );
};

export default ImageDragDropContainer;
