/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Route, Switch, Redirect, BrowserRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from '../Login';

import Landing from '../Landing';
import ForgotPassword from '../ForgotPassword';
import { AppRoutes } from '../../constants/AppConstant';
import ResetPassword from '../ResetPassword';

interface AppInterface {
    token?: string;
    currentToken?: string;
}

const App = (props: AppInterface) => (
    <BrowserRouter>
        <Switch>
            <Route path='/login'>
                <Login />
            </Route>
            <Route exact path={`${AppRoutes.ForgotPassword}`}>
                <ForgotPassword />
            </Route>
            <Route exact path={`${AppRoutes.ResetPassword}`}>
                <ResetPassword />
            </Route>

            <Route path='/'>{!props.token ? <Redirect to={`/login`} /> : <Landing />}</Route>
            {/* This part is required sometimes to login without providing OTP */}
            {/* <Route path='/'>
                    {!token ? <Redirect to={`/login?redirectTo=${window.location.pathname}`} /> : <Roles />}
                    <Landing />
                </Route> */}
        </Switch>
    </BrowserRouter>
);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = ({ globalReducer }: any) => ({ token: globalReducer.token });
const connector = connect(mapStateToProps, null);
export default connector(App);
