import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PASSWORD, REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

type RolesType = { id: string; value: string; name: string }[];

export const rolesOption: RolesType = [
    { id: '1', value: 'Super Admin', name: 'Super Admin' },
    { id: '2', value: 'Admin Agent', name: 'Admin Agent' },
    { id: '3', value: 'Admin Ops', name: 'Admin Ops' },
    { id: '4', value: 'Accountant', name: 'Accountant' },
];

const usersFormData = [
    {
        title: 'User Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: true, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'createdAt',
        label: 'Country Code',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Country Code', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone No',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone No', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [
            {
                pattern: REGEX_PASSWORD,
                message:
                    'Password must contain uppercase character, lowercase character, symbol, and number and of length 8',
            },
        ],
        errorMsg: errorMsg,
        tableMeta: { title: 'Password', columnType: 'password' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'role',
        label: 'Role',
        fieldType: {
            name: 'rolePermissions',
            options: rolesOption,
        },
        placeholder: 'Click here to select an item',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Role', enableSort: false, allowAddRecordClick: false, columnType: 'roleName' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
        readonly: false,
    },
];

export { usersFormData };
