/* eslint-disable @typescript-eslint/no-explicit-any */
import { getToken } from '../service/CookieManager';
import { GlobalActions, UPDATE_PROJECT_DETAILS, UPDATE_STORE, UPDATE_TOKEN } from './actions';

export interface IGlobalReducer {
    selectedProjectDetails: any;
    token: any;
    storeId: null | string;
}

const initialState: IGlobalReducer = {
    selectedProjectDetails: {},
    token: getToken(),
    storeId: null,
};

const globalReducer = (state = initialState, action: GlobalActions): IGlobalReducer => {
    switch (action.type) {
        case UPDATE_PROJECT_DETAILS:
            return {
                ...state,
                selectedProjectDetails: action.payload,
            };
        case UPDATE_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        case UPDATE_STORE:
            return {
                ...state,
                storeId: action.storeId,
            };

        default:
            return state;
    }
};

export default globalReducer;
