import { Button, FormInstance, Select, Card, Form, Input } from 'antd';

const { Option } = Select;

import { useEffect, useState } from 'react';

import { InputInterface } from '../utils/forms/FormInterface';

import QuestionModal from './QuestionModal';

import './style.scss';
import callApi from '../Api/config';
import { toastError, toastSuccess } from '../service/Toast';
import { failedMsg } from '../constants/messages';

interface FormStepsProps {
    formInput: InputInterface;
    index: number;
    form: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}

type question = {
    id: string;
    label: string;
};

type questionTypes = {
    question: question;
    category: string;
    sequence: string;
    isRequired: string;
    stepQuestionId: string;
    isDependent: boolean;
    options: any[];
};

const defaultQuestionDetails = {
    question: { id: '', label: '' },
    category: '',
    sequence: '',
    isRequired: '',
    stepQuestionId: '',
    isDependent: false,
    options: [],
};

const FormWithQuestions = (props: FormStepsProps) => {
    const { formInput, form, additionalData, isCreateMode, formDetails } = props;
    const [questionDetails, setQuestionDetails] = useState<questionTypes>(defaultQuestionDetails);
    const [questions, setQuestions] = useState<any>([]);
    const [formData, setFormData] = useState<any>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [updateQuestion, setUpdateQuestion] = useState<boolean>(false);
    const [activeFormStep, setActiveFormStep] = useState<number>(1);
    const fieldValue = form.getFieldValue(formInput.name);

    const formStepsOptions = [
        { name: '1', value: 1 },
        { name: '2', value: 2 },
        { name: '3', value: 3 },
        { name: '4', value: 4 },
    ];

    const {
        fieldType: { options },
    } = formInput;

    // eslint-disable-next-line no-unused-vars
    const defaultValue = options?.filter(
        (option: any) =>
            fieldValue?.filter(
                (val: any) => val?.stepQuestions?.filter((stepQuestion: any) => stepQuestion?.id === option.value),
            ),
    );

    const handleStepQuestionForm = (name: string) => (value: any) => {
        form.setFieldsValue({
            [name]: value,
        });
        setQuestionDetails((prevState: any) => ({ ...prevState, [name]: value }));
    };

    const handleQuestionSelect = (name: string) => (value: any) => {
        const [id, label, inputType] = value.split(':');
        form.setFieldsValue({
            [name]: value,
        });
        form.setFieldsValue({
            ['isDependent']: inputType === 'dependent' ? true : false,
        });

        setQuestionDetails((prevState: any) => ({
            ...prevState,
            [name]: { id: id, label: label },
            isDependent: inputType === 'dependent' ? true : false,
        }));
    };

    const handleDependentQuestionSelect = (name: string) => (value: any) => {
        const updatedOptions = questionDetails?.options;

        for (let i = 0; i < updatedOptions.length; i++) {
            if (updatedOptions[i]?.value === name) {
                updatedOptions[i].questions = value.map((val: any) => ({ id: val }));
                form.setFieldsValue({
                    [`${activeFormStep}-${name}`]: value,
                });
            }
        }
        setQuestionDetails((prevState: any) => ({ ...prevState, options: [...updatedOptions] }));
    };

    const toggleModal = (index: number, questionData?: questionTypes) => () => {
        if (questionData) {
            setQuestionDetails(questionData);
        }
        setActiveFormStep(index);
        setVisible(!visible);
    };

    const getSequence = (sequence: any, index: number) => {
        if (!isNaN(sequence)) {
            return sequence;
        }
        return index;
    };

    const handleFormSteps = (value: number) => {
        const arr: { sequence: number; stepQuestions: never[]; stepTitle: string }[] = formData?.steps || [];
        const formSteps = questions;
        if (arr.length < value) {
            for (let i = arr.length; i < value; i++) {
                const seq = getSequence(arr[arr.length - 1]?.sequence + 1, i);
                const stepDetails = {
                    sequence: seq,
                    stepQuestions: [],
                    stepTitle: '',
                    stepTime: '',
                };
                formSteps.push([]);
                arr.push(stepDetails);
            }
        } else {
            arr.splice(value - 1);
            formSteps.splice(value - 1);
        }
        setQuestions(formSteps);
        setFormData((prevState: any) => ({ ...prevState, steps: [...arr] }));
    };

    const addToQuestionsList = (
        id: string,
        question: any,
        sequence: string,
        isRequired: boolean,
        category: string,
        isDependent: boolean,
        options: any[],
    ) => {
        const newQuestionDetails = {
            question: question,
            category: category,
            sequence: sequence,
            isRequired: isRequired,
            stepQuestionId: id,
            isDependent: isDependent,
            options: options || [],
        };
        const newQuestions = questions;
        newQuestions[activeFormStep]?.push(newQuestionDetails);
        setQuestions(() => newQuestions);
    };

    const addFormStepsField = (id: string) => {
        const updatedSteps = formData?.steps;
        updatedSteps[activeFormStep].stepQuestions = [...updatedSteps[activeFormStep].stepQuestions, id];
        setFormData((prevState: any) => ({ ...prevState, steps: [...updatedSteps] }));
        form.setFieldsValue({
            ['steps']: [...updatedSteps],
        });
    };

    const handleAddQuestion = (response: any) => {
        const { id, question, sequence, isRequired, category, isDependent, options } = response;

        addToQuestionsList(id, question, sequence, isRequired, category, isDependent, options);

        addFormStepsField(id);

        setQuestionDetails(defaultQuestionDetails);
        setActiveFormStep(-1);
        setVisible(!visible);
        setUpdateQuestion(false);
    };

    const handleUpdateQuestion = (response: any) => {
        const { id, question, sequence, isRequired, category, isDependent, options } = response;

        const newFormData = formData;
        const pos = newFormData?.steps[activeFormStep]?.stepQuestions?.indexOf(id);

        const newQuestions = questions;
        const newQuestionDetails = {
            question: question,
            category: category,
            sequence: sequence,
            isRequired: isRequired,
            stepQuestionId: id,
            isDependent,
            options: options || [],
        };
        if (pos !== -1) {
            newQuestions[activeFormStep].splice(pos, 1, newQuestionDetails);
            setQuestions(() => newQuestions);
            setActiveFormStep(-1);
            setVisible(!visible);
            setUpdateQuestion(false);
        }
    };

    const handleFormData = (index: number) => (event: any) => {
        const newFormSteps = formData?.steps;
        const { name, value } = event.target;
        newFormSteps[index] = { ...newFormSteps[index], [name]: value };
        setFormData((prevState: any) => ({ ...prevState, steps: newFormSteps }));
    };

    const deleteFormStepsField = (index: number, questionID: any) => {
        const newFormData = formData;
        const pos = newFormData?.steps[index]?.stepQuestions?.indexOf(questionID);
        newFormData?.steps[index]?.stepQuestions?.splice(pos, 1);

        setFormData((prevState: any) => ({ ...prevState, steps: [...newFormData.steps] }));
        form.setFieldsValue({
            ['steps']: [...newFormData.steps],
        });
    };

    const handleDeleteQuestion = (index: number, questionID: any) => () => {
        const newQuestions = questions;
        const pos = formData?.steps[index]?.stepQuestions?.indexOf(questionID);
        if (pos !== -1) {
            newQuestions[index]?.splice(pos, 1);
            deleteFormStepsField(index, questionID);
            setQuestions(() => newQuestions);
        }
    };

    const handleAddStepQuestion = (index: number) => () => {
        setUpdateQuestion(false);
        setActiveFormStep(index);
        setVisible(!visible);
        setQuestionDetails(defaultQuestionDetails);
    };

    const toggleUpdateQuestionModal = (index: number, question: any) => () => {
        setUpdateQuestion(true);
        setVisible(!visible);
        setActiveFormStep(index);
        setQuestionDetails(question);
    };

    const deleteEntity = async (formStepId: string, stepIndex: number) => {
        try {
            const res: any = await callApi(`/forms/steps/${formStepId}`, 'DELETE');
            if (res?.status) {
                const newQuestions = questions;
                newQuestions?.splice(stepIndex, 1);
                setQuestions([...newQuestions]);

                const updatedFormSteps = formData?.steps;
                updatedFormSteps?.splice(stepIndex, 1);
                setFormData((prevState: any) => ({ ...prevState, steps: [...updatedFormSteps] }));
                form.setFieldsValue({
                    ['steps']: [...updatedFormSteps],
                });
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const handleDeleteFormStep = (index: number, formStepId: string) => () => {
        if (formStepId) {
            deleteEntity(formStepId, index);
        } else {
            const newQuestions = questions;
            newQuestions?.splice(index, 1);
            setQuestions([...newQuestions]);

            const updatedFormSteps = formData?.steps;
            updatedFormSteps?.splice(index, 1);
            setFormData((prevState: any) => ({ ...prevState, steps: [...updatedFormSteps] }));
            form.setFieldsValue({
                ['steps']: [...updatedFormSteps],
            });
        }
        toastSuccess(`deleted successfully!`);
    };

    useEffect(() => {
        if (!isCreateMode) {
            const ourQuetsions = formDetails?.steps?.map((steps: any) => {
                const stepQuestions = steps?.stepQuestions?.map((question: any) => ({
                    ...question,
                    stepQuestionId: question?.id,
                }));
                return stepQuestions;
            });
            setQuestions(ourQuetsions);
            const ourFormData = formDetails?.steps?.map((steps: any) => {
                const step = steps?.stepQuestions?.map((question: any) => question?.id);
                return { ...steps, stepQuestions: step };
            });
            setFormData((prevFormData: any) => ({ ...prevFormData, steps: ourFormData }));
            form.setFieldsValue({
                ['steps']: ourFormData,
            });
        }
        setQuestionDetails(defaultQuestionDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDetails]);

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontWeight: '500', fontSize: '18px' }} htmlFor='formSteps'>
                    Define Steps
                </label>
                <Select
                    id='formSteps'
                    placeholder='Step Questions'
                    allowClear
                    showSearch
                    optionFilterProp='children'
                    onChange={handleFormSteps}
                    value={questions?.length}
                    style={{ marginTop: '25px', marginBottom: '25px', width: '50%' }}>
                    {formStepsOptions?.map((obj: any) => (
                        <Option key={obj?.value} value={obj?.value} name={obj?.name} selected>
                            {obj?.name}
                        </Option>
                    ))}
                </Select>
            </div>
            <Form.List name={['steps']} key={`stepQuestion-steps`}>
                {() => (
                    <>
                        {questions?.map((step: any, index: number) => (
                            <Card
                                key={index}
                                style={{
                                    marginBottom: '25px',
                                    width: '100%',
                                    borderRadius: '8px',
                                    border: 'dashed 1px #80808091',
                                }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}>
                                        <Form.Item
                                            style={{ marginBottom: '25px', width: '100%' }}
                                            name={[index, 'sequence']}
                                            label='Form'
                                            rules={[{ required: true, message: 'Missing Sequence' }]}>
                                            {index + 1}
                                        </Form.Item>
                                        <Button
                                            style={{
                                                backgroundColor: '#586f80',
                                                color: 'white',
                                                borderRadius: '4px',
                                                border: 'none',
                                                marginRight: '4px',
                                            }}
                                            onClick={handleAddStepQuestion(index)}>
                                            + Add Question
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '4px',
                                                border: 'none',
                                            }}
                                            onClick={handleDeleteFormStep(index, formData?.steps[index]?.id)}>
                                            Delete Step
                                        </Button>
                                    </div>
                                    <div>
                                        <Form.Item
                                            key={`${index}-stepTitle`}
                                            style={{ marginBottom: '25px', width: '100%' }}
                                            name={[index, 'stepTitle']}
                                            label='Step Title'
                                            rules={[{ required: true, message: 'Missing Step Title' }]}>
                                            <Input
                                                key={`${index}-stepTitle`}
                                                name='stepTitle'
                                                placeholder='Step Title'
                                                onChange={handleFormData(index)}
                                                value={formData?.steps[index]?.stepTime}
                                                disabled={formInput.readonly}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            key={`${index}-stepTime`}
                                            style={{ marginBottom: '25px', width: '100%' }}
                                            name={[index, 'stepTime']}
                                            label='Step Time (in minutes)'
                                            rules={[{ required: true, message: 'Missing Step Time' }]}>
                                            <Input
                                                key={`${index}-stepTime`}
                                                name='stepTime'
                                                placeholder='Step Time'
                                                type='number'
                                                onChange={handleFormData(index)}
                                                value={formData?.steps[index]?.stepTime}
                                                disabled={formInput.readonly}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginBottom: '25px', width: '100%' }}
                                            name={[index, 'question']}
                                            label='Added Questions'>
                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                                {step?.map((question: any) => (
                                                    <div
                                                        style={{
                                                            padding: '4px',
                                                            cursor: 'pointer',
                                                            backgroundColor: '#80808029',
                                                            marginBottom: '2px',
                                                            marginLeft: '4px',
                                                            inlineSize: 'fit-content',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                        key={question?.stepQuestionId}>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '7.5px',
                                                            }}
                                                            onClick={toggleUpdateQuestionModal(index, question)}>
                                                            {question?.question?.label}
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginLeft: '6px',
                                                                height: '20px',
                                                                width: '15px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            onClick={handleDeleteQuestion(
                                                                index,
                                                                question?.stepQuestionId,
                                                            )}>
                                                            X
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </Form.Item>
                                    </div>
                                </div>
                            </Card>
                        ))}
                    </>
                )}
            </Form.List>
            <QuestionModal
                visible={visible}
                toggleModal={toggleModal}
                activeFormStep={activeFormStep}
                questionDetails={questionDetails}
                additionalData={additionalData}
                handleAddQuestion={handleAddQuestion}
                handleUpdateQuestion={handleUpdateQuestion}
                handleQuestionSelect={handleQuestionSelect}
                handleDependentQuestionSelect={handleDependentQuestionSelect}
                handleStepQuestionForm={handleStepQuestionForm}
                updateQuestion={updateQuestion}
                setQuestionDetails={setQuestionDetails}
            />
        </div>
    );
};

export default FormWithQuestions;
