import { errorMsg } from '../../constants/messages';

const ProcessStepsData = [
    {
        title: 'Package Step',
        name: 'packages',
        label: 'Package',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Package',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Package',
            enableSort: false,
            allowAddRecordClick: true,
            columnType: 'packageName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'sequence',
        label: 'Sequence',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sequence', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ProcessStepsData };
