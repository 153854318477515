import { errorMsg } from '../constants/messages';

const ConditionsPageFormData = [
    {
        title: 'Conditions Page Details',
        name: 'id',
        label: 'Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'term',
        label: 'Term',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Term', allowAddRecordClick: true, columnType: 'termsName' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textEditor',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { ConditionsPageFormData };
