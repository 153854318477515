import { Button, Form, Input } from 'antd';

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

export interface InputProps {
    formInput: InputInterface;
    index: number;
    form?: any;
    dynamicValidation?: any;
    formDetails?: any;
}

const DynamicCheckboxInput = (props: InputProps) => {
    const { formInput, index, dynamicValidation, formDetails } = props;

    return (
        <>
            {dynamicValidation === 'dropdown' ||
            formDetails?.inputType === 'dropdown' ||
            dynamicValidation === 'dependent' ||
            formDetails?.inputType === 'dependent' ||
            dynamicValidation === 'radio' ||
            formDetails?.inputType === 'radio' ||
            dynamicValidation === 'multiple choice' ||
            formDetails?.inputType === 'multiple choice' ||
            dynamicValidation === undefined ? (
                <Form.List key={index} name={formInput.name}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map(({ key, name }, index) => (
                                <Form.Item
                                    key={key}
                                    wrapperCol={{ span: 8, offset: 4 }}
                                    labelCol={{ span: 4 }}
                                    label={
                                        index === 0 ? (
                                            <div className={!formInput.required ? 'leftOffset' : ''}>
                                                {formInput.label}
                                            </div>
                                        ) : (
                                            <div></div>
                                        )
                                    }
                                    labelAlign='left'
                                    colon={false}>
                                    <div
                                        style={{
                                            columnGap: '8px',
                                        }}>
                                        <Form.Item
                                            name={
                                                formInput.fieldType.valueKey
                                                    ? [name, formInput.fieldType.valueKey]
                                                    : name
                                            }
                                            noStyle
                                            rules={[
                                                {
                                                    required: formInput.required,
                                                    message: formInput.errorMsg,
                                                },
                                            ]}>
                                            <Input placeholder={formInput.placeholder} />
                                        </Form.Item>

                                        {!formInput.readonly && (
                                            <MinusCircleOutlined
                                                className='dynamic-delete-button offset'
                                                onClick={() => remove(name)}
                                            />
                                        )}
                                    </div>
                                </Form.Item>
                            ))}
                            {(dynamicValidation === 'dropdown' ||
                                formDetails?.inputType === 'dropdown' ||
                                dynamicValidation === 'dependent' ||
                                formDetails?.inputType === 'dependent' ||
                                dynamicValidation === 'radio' ||
                                formDetails?.inputType === 'radio' ||
                                dynamicValidation === 'multiple choice' ||
                                formDetails?.inputType === 'multiple choice') && (
                                <Form.Item wrapperCol={{ offset: 8 }} labelCol={{ span: 4 }}>
                                    <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                                        Add Options
                                    </Button>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
            ) : (
                ''
            )}
        </>
    );
};

export default DynamicCheckboxInput;
