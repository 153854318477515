import { errorMsg } from '../../constants/messages';

const ProcessDetailsFormData = [
    {
        title: 'Question Details',
        name: 'service',
        label: 'Service',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service',
            enableSort: false,
            allowAddRecordClick: true,
            columnType: 'displayName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'heading',
        label: 'Heading',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Heading', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'sequence',
        label: 'Sequence',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Sequence' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ProcessDetailsFormData };
