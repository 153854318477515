import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

const { Option } = Select;

import './style.scss';
import callApi from '../Api/config';
import { APPLICATION_FORM_SERVICES, COUNTRY } from '../constants/EntityName';
import { Role } from '../constants/AppConstant';

interface SelectInputProps {
    formInput: InputInterface;
    setFormData?: any;
    form: any;
    index: number;
    isCreateMode?: boolean;
    formDetails?: any;
}

const RolePermissions = (props: SelectInputProps) => {
    const { formInput, form, formDetails } = props;
    const {
        fieldType: { options },
    } = formInput;

    const [allowedServices, setAllowedServices] = useState<boolean>(false);
    const [allowedCountries, setAllowedCountries] = useState<boolean>(false);
    const [serviceList, setServiceList] = useState<any[]>([]);
    const [countriesList, setCountriesList] = useState<any[]>([]);

    const changeHandler = (value: any) => {
        // Find the selected option based on the value
        const selectedOption = options.find((option: any) => option.value === value);
        if (selectedOption?.value === Role.ADMIN_AGENT) {
            setAllowedServices(true);
            setAllowedCountries(true);
        } else {
            setAllowedServices(false);
            setAllowedCountries(false);
        }
    };

    const handleParameterCountries = (name: any) => (value: any) => {
        if (value.includes('all')) {
            const selectedCountries = countriesList.map((country: any) => country?.value);
            form.setFieldValue(name, [...selectedCountries]);
            return;
        }
    };

    const handleParameterServices = (name: any) => (value: any) => {
        if (value.includes('0')) {
            const selectedServices = serviceList.map((service: any) => service?.id);
            form.setFieldValue(name, [...selectedServices]);
            return;
        }
    };

    // This useEffect is used for Country and service List API
    useEffect(() => {
        const fetchCountries = async () => {
            try {
                const entityData: any = await callApi(`${COUNTRY}`, 'GET');
                // Assuming entityData is an array of country names
                const countryNames: string[] = entityData.data.data;

                // Convert array of strings to array of objects with 'value' and 'name' with 'uniqueId' properties
                const countryData = countryNames.map((countryName, index) => ({
                    id: index + 1,
                    value: countryName,
                    name: countryName,
                }));

                if (countryData) {
                    setCountriesList(countryData);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching countries list:', error);
                setCountriesList([]); // Set an empty array in case of an error
            }
        };

        const fetchServices = async () => {
            try {
                const entityData: any = await callApi(`${APPLICATION_FORM_SERVICES}`, 'GET');
                // Assuming entityData is an array of country names

                const services: any[] = entityData?.data?.data?.results;

                const serviceData = services.map((service) => ({
                    id: service?.id,
                    value: service?.name,
                    name: service?.name,
                }));

                if (serviceData) {
                    setServiceList(serviceData);
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching countries list:', error);
                setCountriesList([]); // Set an empty array in case of an error
            }
        };

        fetchCountries();
        fetchServices();
    }, []);

    useEffect(() => {
        if (formDetails?.role === Role.ADMIN_AGENT) {
            setAllowedServices(true);
            setAllowedCountries(true);
            if (formDetails?.servicesAllowed) {
                form.setFieldValue('servicesAllowed', [...formDetails.servicesAllowed]);
            }
            if (formDetails?.countriesAllowed) {
                form.setFieldValue('countriesAllowed', [...formDetails.countriesAllowed]);
            }
        } else {
            setAllowedServices(false);
            setAllowedCountries(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDetails]);

    return (
        <>
            <Form.Item
                label={'Role'}
                name={'role'}
                className='text'
                wrapperCol={{ span: 8, offset: 4 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp='children'
                    onChange={changeHandler}
                    placeholder={formInput.placeholder}
                    disabled={formInput.readonly}
                    style={{ width: '100%' }}>
                    {formInput.fieldType.options?.map((obj: any, index: number) => (
                        <Option key={index} value={obj.value} additionaldata={obj.additionalData}>
                            {obj.name}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            {allowedServices && (
                <Form.Item
                    label='Allowed Services'
                    name='servicesAllowed'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        placeholder='Click here to select service'
                        onChange={handleParameterServices('servicesAllowed')}
                        disabled={formInput.readonly}
                        style={{ width: '100%', maxHeight: '150px', overflow: 'auto' }}>
                        <Option key={0} value={'0'} name={'All Services'} selected>
                            All Services
                        </Option>
                        {serviceList?.map((obj: any) => (
                            <Option key={obj?.id} value={obj.id}>
                                {obj.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}

            {allowedCountries && (
                <Form.Item
                    label='Allowed Country'
                    name='countriesAllowed'
                    wrapperCol={{ span: 8, offset: 4 }}
                    labelCol={{ span: 4 }}
                    labelAlign='left'
                    rules={[
                        {
                            required: formInput.required,
                            message: formInput.errorMsg,
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        placeholder='Click here to select country'
                        onChange={handleParameterCountries('countriesAllowed')}
                        disabled={formInput.readonly}
                        style={{ width: '100%' }}>
                        <Option key={0} value={'all'} name={'All Countries'} selected>
                            All countries
                        </Option>
                        {countriesList?.map((obj: any, index: number) => (
                            <Option key={index + 1} value={obj.value}>
                                {obj.value}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            )}
        </>
    );
};

export default RolePermissions;
