// import { statusOption, storeTypeOptions } from '../constants/AppConstant';
import { errorMsg } from '../constants/messages';
// import { REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

type StatusType = { id: string; value: string; name: string }[];

export const statusOption: StatusType = [
    { id: '1', value: 'Draft', name: 'Draft' },
    { id: '2', value: 'Active', name: 'Active' },
    { id: '3', value: 'Archived', name: 'Archived' },
];

export const fileFormatOptions: StatusType = [
    { id: '1', value: 'PDF', name: 'PDF' },
    { id: '2', value: 'PNG', name: 'PNG' },
    { id: '3', value: 'JPEG', name: 'JPEG' },
];

export const reviewOptions = [
    { id: '1', value: '1', name: '1' },
    { id: '2', value: '2', name: '2' },
    { id: '3', value: '3', name: '3' },
    { id: '4', value: '4', name: '4' },
    { id: '5', value: '5', name: '5' },
];

export const storeTypeOptions: StatusType = [
    { id: '1', value: 'Fresh', name: 'Fresh' },
    { id: '2', value: 'CentralKitchen', name: 'CentralKitchen' },
    { id: '3', value: 'Both', name: 'Both' },
];

const storesFormData = [
    {
        title: 'Store Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'storeInventory',
        label: 'Store Inventory',
        fieldType: {},
        required: true,
        readonly: false,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Store Inventory',
            enableSort: false,
            allowSlotRecordClick: true,
            columnType: 'clickHere',
            url: 'store-inventory',
        },
        isTableHeader: true,
        isAccordian: false,
        default: null,
    },
    // {
    //     name: 'storeVariantPrice',
    //     label: 'Store Variant',
    //     tableMeta: {
    //         title: 'Store Variant',
    //         enableSort: false,
    //         allowSlotRecordClick: true,
    //         columnType: 'clickHere',
    //         url: 'store-variant',
    //     },
    //     fieldType: {},
    //     required: true,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: true,
    //     isAccordian: false,
    //     default: null,
    // },
    // {
    //     name: 'storeAddOns',
    //     label: 'Store Add-ons',
    //     tableMeta: {
    //         title: 'Store Add-ons',
    //         enableSort: false,
    //         allowSlotRecordClick: true,
    //         columnType: 'clickHere',
    //         url: 'store-add-ons',
    //     },
    //     fieldType: {},
    //     required: true,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: true,
    //     isAccordian: false,
    //     default: null,
    // },
    // {
    //     name: 'storeBulkAddOns',
    //     label: 'Store BulkAddons',
    //     tableMeta: {
    //         title: 'Store BulkAddons',
    //         enableSort: false,
    //         allowSlotRecordClick: true,
    //         columnType: 'clickHere',
    //         url: 'store-bulk-add-ons',
    //     },
    //     fieldType: {},
    //     required: true,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: true,
    //     isAccordian: false,
    //     default: null,
    // },
    // {
    //     name: 'slots',
    //     label: 'Delivery Slots',
    //     isTableHeader: true,
    //     placeholder: 'Slots',
    //     tableMeta: {
    //         title: 'Delivery Slots',
    //         enableSort: false,
    //         allowSlotRecordClick: true,
    //         columnType: 'clickHere',
    //         url: 'slots',
    //     },
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [],
    //     },
    //     required: false,
    //     readonly: false,
    //     errorMsg: 'required',
    //     isAccordian: false,
    //     default: [],
    // },
    // {
    //     name: 'deliveryPartners',
    //     label: 'Delivery Partners',
    //     placeholder: 'Click to select Delivery Partners',
    //     tableMeta: { title: 'Delivery Partners', enableSort: false },
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [],
    //     },
    //     required: false,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: false,
    //     isAccordian: true,
    //     default: [],
    // },
    // {
    //     name: 'deliveryPincodes',
    //     label: 'Delivery Pincodes',
    //     placeholder: 'Click to select Pincode',
    //     tableMeta: { title: 'Pin Code', enableSort: false, columnType: 'pincodesArray' },
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [],
    //     },
    //     required: true,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: false,
    //     isAccordian: true,
    //     default: [],
    // },
    // {
    //     name: 'freshSubcategories',
    //     label: 'Fresh Subcategories',
    //     placeholder: 'Click to select Fresh Subcategories',
    //     tableMeta: { title: 'Fresh Subcategories', enableSort: false },
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [],
    //     },
    //     required: false,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: false,
    //     isAccordian: true,
    //     default: [],
    // },
    // {
    //     name: 'kitchenSubcategories',
    //     label: 'Kitchen Subcategories',
    //     placeholder: 'Click to select Kitchen Subcategories',
    //     tableMeta: { title: 'Kitchen Subcategories', enableSort: false },
    //     fieldType: {
    //         name: 'multiSelect',
    //         options: [],
    //     },
    //     required: false,
    //     readonly: false,
    //     errorMsg: errorMsg,
    //     isTableHeader: false,
    //     isAccordian: true,
    //     default: [],
    // },
    // {
    //     name: 'status',
    //     label: 'Status',
    //     fieldType: {
    //         name: 'select',
    //         options: statusOption,
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Status', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: 'Draft',
    // },
    // {
    //     name: 'storeType',
    //     label: 'Store Type',
    //     fieldType: {
    //         name: 'select',
    //         options: storeTypeOptions,
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Store Type', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: 'Fresh',
    // },
    // {
    //     name: 'phone',
    //     label: 'Store Phone Number',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Phone Number', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     title: 'Address',
    //     name: 'line1',
    //     label: 'Line 1',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Line 1', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'line2',
    //     label: 'Line 2',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: false,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Line 2', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'city',
    //     label: 'City',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'City', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'state',
    //     label: 'State',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'State', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'landmark',
    //     label: 'Landmark',
    //     fieldType: {
    //         name: 'text',
    //     },
    //     required: false,
    //     errorMsg: '',
    //     tableMeta: { title: 'Landmark', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'latitude',
    //     label: 'Latitude',
    //     fieldType: {
    //         name: 'number',
    //         subfieldType: 'number',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Latitude', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'longitude',
    //     label: 'Longitude',
    //     fieldType: {
    //         name: 'number',
    //         subfieldType: 'number',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Longitude', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
    // {
    //     name: 'storePincode',
    //     label: 'Store Pincode',
    //     fieldType: {
    //         name: 'pinCode',
    //     },
    //     required: true,
    //     errorMsg: errorMsg,
    //     tableMeta: { title: 'Store Pincode', enableSort: false, allowAddRecordClick: false },
    //     isTableHeader: true,
    //     isAccordian: true,
    //     default: '',
    // },
];

export { storesFormData };
