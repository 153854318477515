import { errorMsg } from '../../constants/messages';

const PhotoStorePlansFormData = [
    {
        title: 'Plans Print Details',
        name: 'id',
        label: 'Id',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'pricePerMember',
        label: 'Plan Price',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Price', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'discountedPricePerMember',
        label: 'Discounted Price',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Discounted Price', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'title',
        label: 'Plan Type',
        fieldType: {
            name: 'select',
            options: [
                { name: 'Digital', value: 'digital' },
                { name: 'Print', value: 'print' },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Plan Type', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
];

export { PhotoStorePlansFormData };
