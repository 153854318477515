import { Modal } from 'antd';

const UserModal = (props: any) => {
    const { openUserModal, setOpenUserModal, currentUser, setCurrentUser, heading } = props;
    return (
        <Modal
            title={`${heading} Details:`}
            visible={openUserModal}
            onOk={() => {
                setCurrentUser(null);
                setOpenUserModal(!openUserModal);
            }}
            onCancel={() => {
                setCurrentUser(null);
                setOpenUserModal(!openUserModal);
            }}
            style={{ width: '600px' }}
            footer={null}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                    <div style={{ fontSize: '16px' }}>Name:</div>
                    <div>{currentUser?.name}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                    <div style={{ fontSize: '16px' }}>Email:</div>
                    <div>{currentUser?.email}</div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: '40px' }}>
                    <div style={{ fontSize: '16px' }}>Phone No:</div>
                    <div>{currentUser?.phone}</div>
                </div>
            </div>
        </Modal>
    );
};

export default UserModal;
