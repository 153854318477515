/* eslint-disable no-unused-vars */
import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';

import { memo, useEffect, useState } from 'react';

import { STEPS_QUESTIONS } from '../constants/EntityName';
import { FormPageData } from '../datamodels/ApplicationFormMaster/FormPageData';
import { getFormDefaultValue } from '../utils/forms/FormDataModel';
import callApi from '../Api/config';
import { failedMsg } from '../constants/messages';
import { toastSuccess, toastError } from '../service/Toast';
import { APPLICATION_FORM_QUESTIONS } from '../constants/EntityName';

const { Option } = Select;

interface questionModalProps {
    visible: boolean;
    toggleModal: (index: number) => (event: any) => void;
    activeFormStep: number;
    questionDetails: any;
    handleAddQuestion: (response: any) => void;
    handleUpdateQuestion: (response: any) => void;
    additionalData: any;
    handleQuestionSelect: (name: string) => (event: any) => void;
    handleDependentQuestionSelect: (name: string) => (event: any) => void;
    handleStepQuestionForm: (name: string) => (event: any) => void;
    updateQuestion: boolean;
    setQuestionDetails: any;
}

const QuestionModal = (props: questionModalProps) => {
    const {
        visible,
        toggleModal,
        activeFormStep,
        questionDetails,
        handleAddQuestion,
        handleUpdateQuestion,
        additionalData,
        handleQuestionSelect,
        handleDependentQuestionSelect,
        handleStepQuestionForm,
        updateQuestion,
        setQuestionDetails,
    } = props;

    const [questionOptions, setQuestionOptions] = useState<any>([]);
    const [optionsList, setOptionsList] = useState<any>([]);

    const [form] = Form.useForm();

    const questionList = additionalData?.[APPLICATION_FORM_QUESTIONS]?.data;

    const requiredOptions = [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
    ];
    const categoryOptions = [
        { name: 'Adult', value: 'Adult' },
        { name: 'Minor', value: 'Minor' },
        { name: 'Both', value: 'Both' },
    ];

    // eslint-disable-next-line no-unused-vars
    const defaultFormValue: any = getFormDefaultValue(FormPageData);

    const createEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(entityName, 'POST', payload);
            if (res?.status) {
                const { data } = res;
                toastSuccess(`created successfully!`);
                handleAddQuestion(data?.data);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };
    const updateEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(`${entityName}/${questionDetails.stepQuestionId}`, 'PATCH', payload);
            if (res?.status) {
                const { data } = res;
                handleUpdateQuestion(data?.data);
                toastSuccess(`updated successfully!`);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const submitForm = () => {
        const payload = {
            question: { id: questionDetails?.question?.id },
            category: questionDetails?.category,
            sequence: questionDetails?.sequence,
            isRequired: questionDetails?.isRequired,
            isDependent: questionDetails?.isDependent,
            options: questionDetails?.options,
        };

        if (updateQuestion) {
            updateEntity(payload, STEPS_QUESTIONS);
        } else {
            createEntity(payload, STEPS_QUESTIONS);
        }
        form.resetFields();
    };

    useEffect(() => {
        form.setFieldsValue({
            ['isDependent']: questionDetails?.isDependent,
        });
        let selectedQuestionOptions = [];
        const nonDependentQuestions = [];
        const updatedOptions: any = [];

        if (questionDetails?.isDependent) {
            for (let i = 0; i < questionList.length; i++) {
                if (questionList[i].id === questionDetails?.question?.id) {
                    selectedQuestionOptions = questionList[i]?.options;
                }
                if (questionList[i]?.inputType !== 'dependent') {
                    nonDependentQuestions.push(questionList[i]);
                }
            }
            for (let i = 0; i < selectedQuestionOptions.length; i++) {
                updatedOptions.push({
                    value: selectedQuestionOptions[i],
                    questions: updateQuestion
                        ? questionDetails?.options[i]?.questions.map((ques: any) => ({ id: ques?.id }))
                        : [],
                });
            }
        }

        setQuestionDetails((prevState: any) => ({ ...prevState, options: [...updatedOptions] }));
        setOptionsList(nonDependentQuestions);
        setQuestionOptions(selectedQuestionOptions);
        if (updateQuestion && questionDetails?.options) {
            for (let i = 0; i < questionDetails?.options?.length; i++) {
                form.setFieldsValue({
                    [`${questionDetails?.question?.id}-${questionDetails?.options[i]?.value}`]:
                        questionDetails?.options[i]?.questions.map((ques: any) => ques?.id),
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questionDetails?.question?.id, updateQuestion]);

    return (
        <Modal
            key={activeFormStep}
            title={`Step Question: ${activeFormStep + 1}`}
            visible={visible}
            onOk={toggleModal(-1)}
            onCancel={toggleModal(-1)}
            maskClosable={false}
            style={{ width: '600px' }}
            footer={null}>
            <Form form={form} onFinish={submitForm}>
                <Form.Item
                    name={[activeFormStep, 'question']}
                    label='Select Question'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Please select question',
                        },
                    ]}>
                    <Select
                        id='question'
                        placeholder='Step Questions'
                        defaultValue={questionDetails?.question?.label}
                        showSearch
                        optionFilterProp='children'
                        onChange={handleQuestionSelect('question')}
                        // value={questionDetails?.question.id}
                        style={{ width: '100%' }}>
                        {questionList?.map((obj: any) => (
                            <Option
                                key={obj?.id}
                                value={`${obj?.id}:${obj?.label}:${obj?.inputType}`}
                                name={obj?.label}
                                selected>
                                {obj?.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[activeFormStep, 'category']}
                    label='Category'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Please select category',
                        },
                    ]}>
                    <Select
                        id='category'
                        placeholder='Select Category'
                        defaultValue={questionDetails?.category}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        onChange={handleStepQuestionForm('category')}
                        style={{ flexGrow: '1' }}>
                        {categoryOptions?.map((obj: any) => (
                            <Option key={obj?.value} value={obj?.value} name={obj?.name} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[activeFormStep, 'isRequired']}
                    label='Required'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Please select isRequired',
                        },
                    ]}>
                    <Select
                        id='isRequired'
                        placeholder='Click here to select an isRequired'
                        defaultValue={questionDetails?.isRequired}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        onChange={handleStepQuestionForm('isRequired')}
                        style={{ width: '100%' }}>
                        {requiredOptions?.map((obj: any, index: number) => (
                            <Option key={obj?.value} value={obj?.value} name={obj?.name} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[activeFormStep, 'sequence']}
                    label='Sequence'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Please select sequence',
                        },
                    ]}>
                    <InputNumber
                        style={{ width: '100%' }}
                        id='sequence'
                        placeholder='Sequence'
                        defaultValue={questionDetails?.sequence}
                        onChange={handleStepQuestionForm('sequence')}
                    />
                </Form.Item>
                {questionOptions?.map((option: any) => (
                    <Form.Item
                        key={`${questionDetails?.question?.id}-${option}`}
                        name={`${questionDetails?.question?.id}-${option}`}
                        label={option}
                        style={{ marginBottom: '25px', width: '100%' }}
                        rules={[
                            {
                                required: false,
                                message: 'Please select sequence',
                            },
                        ]}>
                        <Select
                            id={option}
                            placeholder='Select Questions'
                            showSearch
                            mode='multiple'
                            optionFilterProp='children'
                            onChange={handleDependentQuestionSelect(option)}
                            style={{ width: '100%' }}>
                            {optionsList?.map((obj: any) => (
                                <Option key={obj?.id} value={obj?.id} name={obj?.label} selected>
                                    {obj?.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                ))}

                <Form.Item style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button key='cancel' onClick={toggleModal(-1)}>
                            Cancel
                        </Button>
                        <Button
                            style={{ backgroundColor: '#586f80', color: 'white', borderRadius: '4px', border: 'none' }}
                            type='primary'
                            htmlType='submit'>
                            {updateQuestion ? 'Update Question' : 'Add Question'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default memo(QuestionModal);
