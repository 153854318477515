import { errorMsg } from '../constants/messages';

const categoriesPageFormData = [
    {
        name: 'name',
        label: 'Category Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Category Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'manager',
        label: 'Manager',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an manager',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Manager', allowAddRecordClick: false, columnType: 'displayName' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'asset',
        label: 'Assets',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Assets',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Assets', allowAddRecordClick: false, columnType: 'displayName' },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];

export { categoriesPageFormData };
