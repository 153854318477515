// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changePasswordContainer {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }
  .changePasswordContainer h1 {
    font-size: 32px;
    font-weight: 600;
    text-align: center; }
  .changePasswordContainer form {
    min-width: 33%; }
  .changePasswordContainer .ant-btn-primary {
    width: 100%;
    border-color: #586f80;
    background-color: #586f80; }
  .changePasswordContainer .ant-btn-primary:hover,
  .changePasswordContainer .ant-btn-primary:focus {
    color: #fff;
    border-color: #586f80;
    background: #586f80; }
`, "",{"version":3,"sources":["webpack://./src/pages/ChangePassword/style.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;EAJvB;IAOQ,eAAe;IACf,gBAAgB;IAChB,kBAAkB,EAAA;EAT1B;IAaQ,cAAc,EAAA;EAbtB;IAiBQ,WAAW;IACX,qBAAqB;IACrB,yBAAyB,EAAA;EAnBjC;;IAwBQ,WAAW;IACX,qBAAqB;IACrB,mBAAmB,EAAA","sourcesContent":[".changePasswordContainer {\n    min-height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    h1 {\n        font-size: 32px;\n        font-weight: 600;\n        text-align: center;\n    }\n\n    form {\n        min-width: 33%;\n    }\n\n    .ant-btn-primary {\n        width: 100%;\n        border-color: #586f80;\n        background-color: #586f80;\n    }\n\n    .ant-btn-primary:hover,\n    .ant-btn-primary:focus {\n        color: #fff;\n        border-color: #586f80;\n        background: #586f80;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
