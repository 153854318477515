import { Button, Form, Input, InputNumber, Modal, Select } from 'antd';

import { useEffect } from 'react';

import {
    APPLICATION_FORM_PACKAGE_DOCUMENTS,
    APPLICATION_FORM_PACKAGE_FEATURES,
    APPLICATION_FORM_PACKAGE_PLANS,
} from '../../constants/EntityName';
import callApi from '../../Api/config';
import { failedMsg } from '../../constants/messages';
import { toastSuccess, toastError } from '../../service/Toast';
import { Plans } from './planTypes';

const { Option } = Select;

const PlanCreationModal = (props: any) => {
    const {
        showPlanModal,
        setShowPlanModal,
        additionalData,
        typeOfPlan,
        onlineConsultationDetails,
        setOnlineConsultationDetails,
        readyToShipDetails,
        setReadyToShipDetails,
        packageForm,
        packageDetails,
        isCreateMode,
        selectedPlans,
        setSelectedPlans,
    } = props;
    const featuresList = additionalData?.[APPLICATION_FORM_PACKAGE_FEATURES]?.data;
    const documentsList = additionalData?.[APPLICATION_FORM_PACKAGE_DOCUMENTS]?.data;

    const [form] = Form.useForm();

    const handlePlanDetailsChange = (name: string) => (value: any) => {
        if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
            form.setFieldsValue({
                [typeOfPlan]: { ...onlineConsultationDetails, [name]: value },
            });
            setOnlineConsultationDetails((prevState: any) => ({ ...prevState, [name]: value }));
        } else {
            form.setFieldsValue({
                [typeOfPlan]: { ...readyToShipDetails, [name]: value },
            });
            setReadyToShipDetails((prevState: any) => ({ ...prevState, [name]: value }));
        }
    };

    const createEntity = async (payload: any, entityName: string) => {
        if (payload.discountedPrice >= payload.price) {
            toastError('Discounted price should be less than the Original price!!');
            return;
        }
        try {
            const res: any = await callApi(entityName, 'POST', payload);
            if (res?.status) {
                const { data } = res;
                const createdPlan = data?.data;
                if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
                    setOnlineConsultationDetails(() => ({
                        ...createdPlan,
                        features: createdPlan?.features?.map((feature: any) => feature?.id),
                        documents: createdPlan?.documents?.map((doc: any) => doc?.id),
                    }));
                    const newPlans = selectedPlans;
                    newPlans[0] = data?.data?.id;
                    setSelectedPlans(newPlans);
                    packageForm.setFieldsValue({
                        plans: [...newPlans],
                    });
                } else {
                    setReadyToShipDetails(() => ({
                        ...createdPlan,
                        features: createdPlan?.features?.map((feature: any) => feature?.id),
                        documents: createdPlan?.documents?.map((doc: any) => doc?.id),
                    }));
                    const newPlans = selectedPlans;
                    newPlans[1] = data?.data?.id;
                    setSelectedPlans(newPlans);
                    packageForm.setFieldsValue({
                        plans: [...newPlans],
                    });
                }
                // form.setFieldsValue({
                //     [typeOfPlan]: { ...data?.data },
                // });
                setShowPlanModal(!showPlanModal);
                toastSuccess(`created successfully!`);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };
    const updateEntity = async (payload: any, entityName: string, planId: string) => {
        try {
            const res: any = await callApi(`${entityName}/${planId}`, 'PATCH', payload);
            if (res?.status) {
                const { data } = res;
                const updatedPlan = data?.data;
                if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
                    setOnlineConsultationDetails(() => ({
                        ...updatedPlan,
                        features: updatedPlan?.features?.map((feature: any) => feature?.id),
                        documents: updatedPlan?.documents?.map((doc: any) => doc?.id),
                    }));
                    const newPlans = selectedPlans;
                    newPlans[0] = data?.data?.id;
                    setSelectedPlans(newPlans);
                    packageForm.setFieldsValue({
                        plans: [...newPlans],
                    });
                } else {
                    setReadyToShipDetails(() => ({
                        ...updatedPlan,
                        features: updatedPlan?.features?.map((feature: any) => feature?.id),
                        documents: updatedPlan?.documents?.map((doc: any) => doc?.id),
                    }));
                    const newPlans = selectedPlans;
                    newPlans[1] = data?.data?.id;
                    setSelectedPlans(newPlans);
                    packageForm.setFieldsValue({
                        plans: [...newPlans],
                    });
                }
                setShowPlanModal(!showPlanModal);
                toastSuccess(`updated successfully!`);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const onSubmit = (value: any) => {
        form.setFieldsValue({
            [typeOfPlan]: { ...value[typeOfPlan] },
        });

        const payload = {
            discountedPrice: Number(value[typeOfPlan]?.discountedPrice),
            documents: value[typeOfPlan]?.documents,
            features: value[typeOfPlan]?.features,
            price: Number(value[typeOfPlan]?.price),
            title: value[typeOfPlan]?.title,
        };
        if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
            if (!selectedPlans[0]) {
                createEntity(payload, APPLICATION_FORM_PACKAGE_PLANS);
            } else {
                updateEntity(payload, APPLICATION_FORM_PACKAGE_PLANS, selectedPlans[0]);
            }
        } else {
            if (!selectedPlans[1]) {
                createEntity(payload, APPLICATION_FORM_PACKAGE_PLANS);
            } else {
                updateEntity(payload, APPLICATION_FORM_PACKAGE_PLANS, selectedPlans[1]);
            }
        }
    };

    const chekAddUpdateButton = () => {
        if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
            return selectedPlans[0] ? 'Update Plan' : 'Add Plan';
        } else {
            return selectedPlans[1] ? 'Update Plan' : 'Add Plan';
        }
    };

    useEffect(() => {
        if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
            form.setFieldsValue({
                [typeOfPlan]: { ...onlineConsultationDetails, title: typeOfPlan },
            });
            setOnlineConsultationDetails({ ...onlineConsultationDetails, title: typeOfPlan });
        } else {
            form.setFieldsValue({
                [typeOfPlan]: { ...readyToShipDetails, title: typeOfPlan },
            });
            setReadyToShipDetails({ ...readyToShipDetails, title: typeOfPlan });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeOfPlan]);

    useEffect(() => {
        // Check if it's in create mode or packageDetails is not available
        if (isCreateMode || !packageDetails) {
            return;
        }

        // Destructure plans array from packageDetails
        const { plans } = packageDetails;

        // Destructure the first two plans from the plans array
        let [firstPlan, secondPlan] = plans;

        // Swap the plans if the title of the first plan is not ONLINE_CONSULTATION
        if (firstPlan?.title !== Plans.ONLINE_CONSULTATION) {
            const anotherPlan = firstPlan;
            firstPlan = secondPlan;
            secondPlan = anotherPlan;
        }

        // Extract document and feature ids from the first and second plans
        const firstDocuments = firstPlan?.documents?.map((doc: any) => doc?.id);
        const firstFeatures = firstPlan?.features?.map((feature: any) => feature?.id);
        const secondDocuments = secondPlan?.documents?.map((doc: any) => doc?.id);
        const secondFeatures = secondPlan?.features?.map((feature: any) => feature?.id);

        // Set the details for onlineConsultation and readyToShip using the extracted data
        setOnlineConsultationDetails(() => ({
            ...firstPlan,
            documents: firstDocuments,
            features: firstFeatures,
        }));
        setReadyToShipDetails(() => ({
            ...secondPlan,
            documents: secondDocuments,
            features: secondFeatures,
        }));

        // Depending on the typeOfPlan, set fields value in the form
        if (typeOfPlan === Plans.ONLINE_CONSULTATION) {
            form.setFieldsValue({
                [typeOfPlan]: {
                    ...onlineConsultationDetails,
                    price: Number(firstPlan?.price),
                    discountedPrice: Number(firstPlan?.discountedPrice),
                },
            });
        } else {
            form.setFieldsValue({
                [typeOfPlan]: {
                    ...readyToShipDetails,
                    price: Number(secondPlan?.price),
                    discountedPrice: Number(secondPlan?.discountedPrice),
                },
            });
        }

        // Extract ids from the plans and update the selectedPlans state
        const updatedPlans = [plans[0]?.id, plans[1]?.id];
        setSelectedPlans(updatedPlans);

        // Set the plans field value in the packageForm
        packageForm.setFieldsValue({
            plans: [...updatedPlans],
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [packageDetails, typeOfPlan]);

    return (
        <Modal
            key={typeOfPlan}
            title={`Package Plan:`}
            visible={showPlanModal}
            onOk={() => setShowPlanModal(!showPlanModal)}
            onCancel={() => setShowPlanModal(!showPlanModal)}
            style={{ width: '600px' }}
            maskClosable={false}
            footer={null}>
            <Form key={typeOfPlan} form={form} onFinish={onSubmit}>
                <Form.Item
                    name={[typeOfPlan, 'title']}
                    label='Plan Title'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select title',
                        },
                    ]}>
                    <Input
                        placeholder='Enter plan title'
                        defaultValue={typeOfPlan}
                        onChange={handlePlanDetailsChange('title')}
                        type='text'
                        // value={typeOfPlan}
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    name={[typeOfPlan, 'price']}
                    label='Price ($)'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select price',
                        },
                    ]}>
                    <InputNumber
                        style={{ width: '100%' }}
                        id='price'
                        placeholder='Enter price'
                        defaultValue={
                            typeOfPlan === Plans.ONLINE_CONSULTATION
                                ? onlineConsultationDetails?.price
                                : readyToShipDetails?.price
                        }
                        onChange={handlePlanDetailsChange('price')}
                    />
                </Form.Item>
                <Form.Item
                    name={[typeOfPlan, 'discountedPrice']}
                    label='Discounted Price ($)'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select discounted price',
                        },
                    ]}>
                    <InputNumber
                        style={{ width: '100%' }}
                        id='discountedPrice'
                        placeholder='Enter discounted price'
                        defaultValue={
                            typeOfPlan === Plans.ONLINE_CONSULTATION
                                ? onlineConsultationDetails?.discountedPrice
                                : readyToShipDetails?.discountedPrice
                        }
                        onChange={handlePlanDetailsChange('discountedPrice')}
                    />
                </Form.Item>
                <Form.Item
                    name={[typeOfPlan, 'features']}
                    label='Features'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select features',
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        placeholder={'Select features'}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        style={{ width: '100%' }}
                        // disabled={formInput.readonly && !isCreateMode}
                        defaultValue={
                            typeOfPlan === Plans.ONLINE_CONSULTATION
                                ? onlineConsultationDetails?.features
                                : readyToShipDetails?.features
                        }
                        onChange={handlePlanDetailsChange('features')}>
                        {featuresList?.map((obj: any) => (
                            <Option key={obj?.id} value={obj?.id} selected>
                                {obj?.description}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={[typeOfPlan, 'documents']}
                    label='Documents'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Please select documents',
                        },
                    ]}>
                    <Select
                        mode='multiple'
                        placeholder={'Select documents'}
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        style={{ width: '100%' }}
                        // disabled={formInput.readonly && !isCreateMode}
                        defaultValue={
                            typeOfPlan === Plans.ONLINE_CONSULTATION
                                ? onlineConsultationDetails?.documents
                                : readyToShipDetails?.documents
                        }
                        onChange={handlePlanDetailsChange('documents')}>
                        {documentsList?.map((obj: any) => (
                            <Option key={obj?.id} value={obj?.id} selected>
                                {obj?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button key='cancel' onClick={() => setShowPlanModal(!showPlanModal)}>
                            Cancel
                        </Button>
                        <Button
                            style={{ backgroundColor: '#586f80', color: 'white', borderRadius: '4px', border: 'none' }}
                            type='primary'
                            htmlType='submit'>
                            {chekAddUpdateButton()}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PlanCreationModal;
