import { useEffect, useState } from 'react';
import { Modal, Image, Form, Button, Input } from 'antd';

import { DOCUMITRA_ORDER_APPLICATION } from '../constants/EntityName';
import callApi from '../Api/config';
import { failedMsg } from '../constants/messages';
import { toastSuccess, toastError } from '../service/Toast';
import { fallback } from '../constants/FallbackImage';
import { BASE_IMAGE_URL } from '../env';

const PhotoRemarksModal = (props: any) => {
    const [form] = Form.useForm();
    const { activePhoto, openAddRemark, setOpenAddRemarks, formDetails } = props;
    const [remarks, setRemarks] = useState<string>('');

    const handleRemarks = (e: any) => {
        setRemarks(e.target.value);
    };

    const createEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(`${entityName}/${formDetails?.id}`, 'PATCH', payload);
            if (res?.status) {
                setRemarks('');
                form.setFieldValue(`${activePhoto?.id}-remarks`, '');
                setOpenAddRemarks(!openAddRemark);
                toastSuccess(`created successfully!`);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const handleImageRemarks = (value: any) => {
        const payload = {
            photo: {
                photoId: activePhoto?.id,
                remarks: value?.[`${activePhoto?.id}-remarks`],
            },
        };
        createEntity(payload, DOCUMITRA_ORDER_APPLICATION);
    };

    useEffect(() => {
        if (activePhoto) {
            setRemarks(activePhoto?.remark || '');
            form.setFieldValue(`${activePhoto?.id}-remarks`, activePhoto?.remark || '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePhoto, openAddRemark]);

    return (
        <Modal
            key={activePhoto?.id}
            title={activePhoto?.name}
            visible={openAddRemark}
            onOk={() => setOpenAddRemarks(!openAddRemark)}
            onCancel={() => setOpenAddRemarks(!openAddRemark)}
            style={{ width: '600px' }}
            footer={null}>
            <Image
                width={470}
                src={
                    activePhoto?.url?.startsWith('https') || !activePhoto?.url
                        ? activePhoto?.url
                        : `${BASE_IMAGE_URL}/${activePhoto?.url}`
                }
                fallback={fallback}
            />
            <Form key={activePhoto?.id} form={form} onFinish={handleImageRemarks}>
                <Form.Item
                    name={`${activePhoto?.id}-remarks`}
                    label='Add Remarks'
                    style={{ marginBottom: '25px', marginTop: '25px', width: '100%' }}
                    rules={[
                        {
                            required: false,
                            message: 'Please select question',
                        },
                    ]}>
                    <Input
                        style={{ width: '100%' }}
                        id='remarks'
                        placeholder='Enter Remarks'
                        defaultValue={remarks}
                        onChange={handleRemarks}
                    />
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button key='cancel' onClick={() => setOpenAddRemarks(!openAddRemark)}>
                            Cancel
                        </Button>
                        <Button
                            style={{ backgroundColor: '#586f80', color: 'white', borderRadius: '4px', border: 'none' }}
                            type='primary'
                            htmlType='submit'>
                            {activePhoto?.remark ? 'Update Remark' : 'Add Remarks'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PhotoRemarksModal;
