import { Button, Card, Form, FormInstance } from 'antd';

import { useEffect, useState } from 'react';

import { InputInterface } from '../utils/forms/FormInterface';
import AttributeModal from './AttributeModal';

interface propTypes {
    formInput: InputInterface;
    index: number;
    parentForm: FormInstance;
    isCreateMode?: boolean;
    additionalData?: any;
    formDetails?: any;
}
const ServiceAttributeSelection = (props: propTypes) => {
    const { formDetails, formInput, index, parentForm, isCreateMode } = props;
    const [form] = Form.useForm();
    const [openAttributeModal, setOpenAttributeModal] = useState<boolean>(false);
    const [attributes, setAtrributes] = useState<any>([]);
    const [currentAttribute, setCurrentAttribute] = useState<any>(null);

    const handleDeleteAttribute = (index: number) => {
        const updatedAttributes = attributes;
        updatedAttributes.splice(index, 1);
        setAtrributes(() => [...updatedAttributes]);
        parentForm.setFieldValue('serviceAttributes', attributes);
        form.setFieldValue(`${index}-name`, '');
        form.setFieldValue(`${index}-value`, '');
        form.setFieldValue(`${index}-iconImage`, '');
        setCurrentAttribute(null);
    };

    useEffect(() => {
        if (!formDetails) {
            return;
        }
        setAtrributes(formDetails?.serviceAttributes);
        parentForm.setFieldValue('serviceAttributes', formDetails?.serviceAttributes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDetails]);

    return (
        <>
            <Form.Item
                name={'serviceAttributes'}
                label='Add Service Attributes'
                wrapperCol={{ span: 8, offset: 4 }}
                labelCol={{ span: 4 }}
                labelAlign='left'
                rules={[
                    {
                        required: formInput.required,
                        message: formInput.errorMsg,
                    },
                ]}>
                <Card
                    key={index}
                    style={{
                        marginBottom: '25px',
                        width: '566px',
                        borderRadius: '8px',
                        border: 'solid 1px #d9d9d9',
                    }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {attributes?.map((attribute: any, index: number) => (
                                <div
                                    style={{
                                        padding: '4px',
                                        cursor: 'pointer',
                                        backgroundColor: '#80808029',
                                        marginBottom: '2px',
                                        marginLeft: '4px',
                                        inlineSize: 'fit-content',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                    key={attribute?.id}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            borderRadius: '7.5px',
                                        }}
                                        onClick={() => {
                                            setCurrentAttribute(index);
                                            setOpenAttributeModal(!openAttributeModal);
                                        }}>
                                        {attribute?.name}
                                    </div>
                                    {isCreateMode && (
                                        <div
                                            style={{
                                                marginLeft: '6px',
                                                height: '20px',
                                                width: '15px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                            onClick={() => handleDeleteAttribute(index)}>
                                            X
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            {isCreateMode && (
                                <Button
                                    style={{
                                        backgroundColor: '#586f80',
                                        color: 'white',
                                        borderRadius: '4px',
                                        border: 'none',
                                        marginRight: '4px',
                                    }}
                                    onClick={() => setOpenAttributeModal(!openAttributeModal)}>
                                    + Add Service Attributes
                                </Button>
                            )}
                        </div>
                    </div>
                </Card>
            </Form.Item>
            <AttributeModal
                parentForm={parentForm}
                childFrom={form}
                currentAttribute={currentAttribute}
                setCurrentAttribute={setCurrentAttribute}
                attributes={attributes}
                openAttributeModal={openAttributeModal}
                setOpenAttributeModal={setOpenAttributeModal}
                setAtrributes={setAtrributes}
                isCreateMode={isCreateMode}
            />
        </>
    );
};

export default ServiceAttributeSelection;
