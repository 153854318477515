import { errorMsg } from '../../constants/messages';

const fileTypeFormData = [
    {
        title: 'File Type Details',
        name: 'value',
        label: 'Type',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Type', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { fileTypeFormData };
