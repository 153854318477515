import { acceptableImageFormat } from '../../constants/FilterDataValues';
import { errorMsg } from '../../constants/messages';

const PackageServiceAttributesFormData = [
    {
        title: 'Service Attributes Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'value',
        label: 'Value',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: ' Value' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'iconImage',
        label: 'Attribute Icon (*.png, *jpg)',
        fieldType: {
            name: 'documitraFile',
            accept: acceptableImageFormat,
            maxCount: '1',
            allowImageCropping: true,
        },
        placeholder: 'Click here to select',
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Attribute Icon', enableSort: false, allowAddRecordClick: false },
        isTableHeader: false,
        isAccordian: true,
    },
];

export { PackageServiceAttributesFormData };
