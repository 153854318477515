import { Button, Form, Input, InputNumber, Modal } from 'antd';

import { useEffect } from 'react';
import ReactQuill from 'react-quill';

import callApi from '../Api/config';
import { toastError, toastSuccess } from '../service/Toast';
import { failedMsg } from '../constants/messages';
import { PACKAGE_PROCESS_SUBTITLE } from '../constants/EntityName';

const SubTitleModal = (props: any) => {
    const {
        currentSubTitle,
        setCurrentSubTitle,
        subTitles,
        setSubTitles,
        openSubTitleModal,
        setOpenSubTitleModal,
        parentForm,
        childFrom,
    } = props;

    const newIndex = currentSubTitle !== null ? currentSubTitle : subTitles?.length;

    const createEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(entityName, 'POST', payload);
            if (res?.status) {
                const { data } = res;
                toastSuccess(`created successfully!`);
                setSubTitles((prevState: any) => {
                    parentForm.setFieldValue('subtitle', [...prevState, data?.data]);
                    return [...prevState, data?.data];
                });
                setOpenSubTitleModal(!openSubTitleModal);
                childFrom.resetFields();
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const updateEntity = async (payload: any, entityName: string) => {
        try {
            const res: any = await callApi(`${entityName}/${subTitles[currentSubTitle]?.id}`, 'PATCH', payload);
            if (res?.status) {
                const { data } = res;
                toastSuccess(`updated successfully!`);
                const updatedAttributes = subTitles;
                updatedAttributes[currentSubTitle] = data?.data;

                setSubTitles(() => {
                    parentForm.setFieldValue('subtitle', [...updatedAttributes]);
                    return [...updatedAttributes];
                });
                setCurrentSubTitle(null);
                setOpenSubTitleModal(!openSubTitleModal);
            } else {
                toastError(`Oops, an error occured! creation failed. ${res?.data?.message || failedMsg}`);
            }
        } catch (err) {
            toastError(`Oops, an error occured! creation failed. Please contact development support team`);
        }
    };

    const submitForm = (values: any) => {
        const payload = {
            heading: values[`${newIndex}-heading`],
            description: values[`${newIndex}-description`],
            sequence: values[`${newIndex}-sequence`],
        };

        if (currentSubTitle !== null) {
            updateEntity(payload, PACKAGE_PROCESS_SUBTITLE);
        } else {
            createEntity(payload, PACKAGE_PROCESS_SUBTITLE);
        }
        childFrom.resetFields();
    };

    const closeModal = () => {
        childFrom.setFieldValue(`${currentSubTitle}-heading`, '');
        childFrom.setFieldValue(`${currentSubTitle}-description`, '');
        childFrom.setFieldValue(`${currentSubTitle}-sequence`, null);
        setCurrentSubTitle(null);
        setOpenSubTitleModal(!openSubTitleModal);
    };

    useEffect(() => {
        if (currentSubTitle !== null) {
            childFrom.setFieldValue(`${currentSubTitle}-heading`, subTitles[currentSubTitle]?.heading);
            childFrom.setFieldValue(`${currentSubTitle}-description`, subTitles[currentSubTitle]?.description);
            childFrom.setFieldValue(`${currentSubTitle}-sequence`, subTitles[currentSubTitle]?.sequence);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSubTitle]);

    const modules = {
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ size: ['small', false, 'large', 'huge'] }, { color: [] }],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, { align: [] }],
                ['link', 'image'],
                ['clean'],
                ['code-block'],
            ],
        },
        clipboard: { matchVisual: false },
    };

    return (
        <Modal
            title={'Add Sub Title'}
            open={openSubTitleModal}
            onOk={() => closeModal()}
            onCancel={() => closeModal()}
            footer={null}>
            <Form form={childFrom} onFinish={submitForm}>
                <Form.Item
                    name={`${newIndex}-heading`}
                    label='Title'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute name',
                        },
                    ]}>
                    <Input
                        type='text'
                        key={`${newIndex}-heading`}
                        name='heading'
                        placeholder='Enter'
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    name={`${newIndex}-description`}
                    label='Description'
                    className='textArea'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute name',
                        },
                    ]}>
                    <ReactQuill
                        key={`${newIndex}-description`}
                        style={{ minWidth: '300px' }}
                        theme='snow'
                        modules={modules}
                    />
                </Form.Item>
                <Form.Item
                    name={`${newIndex}-sequence`}
                    label='Sequence'
                    style={{ marginBottom: '25px', width: '100%' }}
                    rules={[
                        {
                            required: true,
                            message: 'Missing attribute name',
                        },
                    ]}>
                    <InputNumber
                        key={`${newIndex}-sequence`}
                        name='sequence'
                        placeholder='Enter'
                        style={{ width: '100%' }}
                    />
                </Form.Item>
                <Form.Item style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'end', gap: '10px' }}>
                        <Button key='cancel' onClick={() => setOpenSubTitleModal(!openSubTitleModal)}>
                            Cancel
                        </Button>
                        <Button
                            style={{ backgroundColor: '#586f80', color: 'white', borderRadius: '4px', border: 'none' }}
                            type='primary'
                            htmlType='submit'>
                            {currentSubTitle !== null ? 'Update Sub Title' : 'Add Sub Title'}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default SubTitleModal;
