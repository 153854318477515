import { Form, InputNumber } from 'antd';

import { InputInterface } from '../utils/forms/FormInterface';

import './style.scss';

interface NumberProps {
    formInput: InputInterface;
    index: number;
}

const NumberInput = (props: NumberProps) => {
    const { formInput, index } = props;

    return (
        <Form.Item
            key={index}
            className='text'
            name={formInput.name}
            wrapperCol={{ span: 8, offset: 4 }}
            labelCol={{ span: 4 }}
            label={<div className={!formInput.required ? 'leftOffset' : ''}>{formInput.label}</div>}
            labelAlign='left'
            // rules={[
            //     {
            //         required: formInput.required,
            //         message: formInput.errorMsg,
            //     },
            // ]}
            rules={[
                {
                    required: formInput.required,
                    message: formInput.errorMsg,
                },
                ...((formInput.rules ? formInput.rules : []) as Array<any>),
            ]}>
            {['documitraFees', 'governmentFees'].includes(formInput.name) ? (
                <InputNumber style={{ width: '100%' }} addonAfter={formInput.fieldType.addonAfter} maxLength={4} />
            ) : (
                <InputNumber style={{ width: '100%' }} addonAfter={formInput.fieldType.addonAfter} />
            )}
        </Form.Item>
    );
};

export default NumberInput;
