import { errorMsg } from '../../constants/messages';

const FormStepsData = [
    {
        title: 'Form Steps',
        name: 'stepTitle',
        label: 'Step Title',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Step Title', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'stepNumber',
        label: 'Steps Number',
        fieldType: {
            name: 'number',
        },
        required: true,
        errorMsg: 'Form steps should be in number',
        tableMeta: { title: 'Steps Number' },
        isTableHeader: true,
        isAccordian: true,
    },
    {
        name: 'questions',
        label: 'questions',
        fieldType: {
            name: 'multiSelect',
            options: [],
        },
        placeholder: 'Click here to select an questions',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'questions',
            enableSort: false,
            allowAddRecordClick: false,
            columnType: 'questionsDisplayNameObject',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
];

export { FormStepsData };
