import { errorMsg } from '../../constants/messages';

const ServiceTimeLineModel = [
    {
        title: 'Service Timeline Details:',
        name: 'id',
        label: 'Timeline ID',
        fieldType: {
            name: 'text',
        },
        required: false,
        errorMsg: errorMsg,
        readonly: true,
        tableMeta: { title: 'Timeline Table ID', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
    },
    {
        name: 'service',
        label: 'Service',
        fieldType: {
            name: 'select',
            options: [],
        },
        placeholder: 'Click here to select an Service',
        required: true,
        errorMsg: errorMsg,
        tableMeta: {
            title: 'Service',
            enableSort: false,
            allowAddRecordClick: true,
            columnType: 'displayName',
        },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
    },
    {
        name: 'name',
        label: 'Table Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Table Name' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'table',
        label: 'Table Details',
        fieldType: {
            name: 'serviceTimeline',
        },
        required: false,
        errorMsg: errorMsg,
        tableMeta: { title: 'Review Table', columnType: 'clickHere', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ServiceTimeLineModel };
