import { errorMsg } from '../constants/messages';

const ContactInfoPageFormData = [
    {
        title: 'Contact Info Details',
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'contactNumbers',
        label: 'Contact Numbers',
        fieldType: {
            name: 'multipleTextInput',
            minCount: 1,
            maxCount: 3,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Contact Numbers', columnType: 'contactNumbersMultiple' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'facebook',
        label: 'Facebook Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Facebook Link', columnType: 'facebookLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'facebookGroup',
        label: 'Facebook Group Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Facebook Group Link', columnType: 'facebookGroupLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'instagram',
        label: 'Instagram Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Instagram Link', columnType: 'instagramLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'linkedIn',
        label: 'LinkedIn Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'LinkedIn Link', columnType: 'linkedInLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'youtube',
        label: 'Youtube Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Youtube Link', columnType: 'youtubeLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'whatsappChat',
        label: 'Whatsapp Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Youtube Link', columnType: 'whatsappLink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'whatsappGroup',
        label: 'Whatsapp Group Link',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Youtube Link', columnType: 'whatsappGrouplink' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { ContactInfoPageFormData };
