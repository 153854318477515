import { errorMsg } from '../../constants/messages';

const FormPackageFeaturesFormData = [
    {
        title: 'Package Feature Details',
        name: 'id',
        label: 'Id',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Id', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: false,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { FormPackageFeaturesFormData };
